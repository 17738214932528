import { isEmpty } from 'lodash'
import { useRef, useState } from "react"

//images
import bchev from '../../../assets/icons/chevup.svg'

export const DeliveryDays = ({ handleDeliveryDates }) => {
    const [state, setState] = useState({
        day: ''
    })

    const [activeContainer, setActiveContainer] = useState(null);
    const containerRef = useRef([]);

    const toggleContainer = (index) => activeContainer === index ? setActiveContainer(null) : setActiveContainer(index);

    const toggleDropDown = (index) => toggleContainer(index)

    const closeModal = () => setActiveContainer(null)

    const handleInput = (day) => {
        setState({ ...state, day: day })

        handleDeliveryDates(day)
        closeModal()
    }

    const data = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    return (
        <div className="relative z-10 flex flex-col justify-start w-full gap-4">
            <span className="flex items-center justify-between w-full p-3 text-sm font-medium text-center text-gray-500 bg-white border rounded shadow cursor-pointer" onClick={() => toggleDropDown(0)}>
                <span className='flex items-center justify-start gap-2'>
                    <span className="text-left">
                        {state.day || 'Select A Day'}
                    </span>
                </span>
                <img src={bchev} alt='Up' loading='lazy' className={`w-5 ${activeContainer === 0 && 'rotate-180'} lg:ease-in-out duration-300`} />
            </span>
            <div className={`absolute bg-white shadow rounded top-14 w-full h-[200px] overflow-y-scroll ${activeContainer === 0 ? '' : 'hidden'} fade-in__left`} ref={(ref) => (containerRef.current[0] = ref)}>
                {
                    isEmpty(data) ?
                        <p className='flex items-center justify-start w-full gap-2 p-2 text-sm font-medium leading-none text-gray-500 cursor-pointer hover:bg-grey lg:p-4 last:rounded-b first:rounded-t'>
                            No stores available
                        </p>
                        :
                        data?.map((day, index) => (
                            <p className='flex items-center justify-start w-full gap-2 p-2 text-sm font-medium leading-none text-gray-500 cursor-pointer hover:bg-grey lg:p-4 last:rounded-b first:rounded-t' name='day' key={index} onClick={() => handleInput(day)}>
                                {day}
                            </p>
                        ))
                }
            </div>
        </div>
    )
}
