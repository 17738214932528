import { useState, useRef } from "react"
import { isEmpty } from 'lodash'

//images
import { PriceTiers } from "./getPriceTiers";
import alert from '../../../assets/icons/alert.svg';
import bchev from '../../../assets/icons/chevup.svg';

export const PriceList = ({ handleAssignedTiers }) => {
    const [activeContainer, setActiveContainer] = useState(null);
    const containerRef = useRef([]);
    const [tier, setTier] = useState(null);

    const tiers = PriceTiers()

    const assignTier = (tier) => {
        setTier(tier)
        closeDropDown()
        handleAssignedTiers(tier)
    }

    const toggleContainer = (index) => activeContainer === index ? setActiveContainer(null) : setActiveContainer(index);

    const toggleDropDown = (index) => toggleContainer(index)

    const closeDropDown = () => setActiveContainer(null)

    return (
        <div className="relative z-10 flex flex-col justify-start w-full gap-4">
            <span className="flex items-center justify-between w-full p-3 text-sm font-medium text-center text-gray-500 bg-white border rounded shadow cursor-pointer" onClick={() => toggleDropDown(0)}>
                <span className='flex items-center justify-start gap-2'>
                    <span className="text-left">
                        {tier?.description || 'Select A Pricing Tier'}
                    </span>
                </span>
                <img src={bchev} alt='Up' loading='lazy' className={`w-5 ${activeContainer === 0 && 'rotate-180'} lg:ease-in-out duration-300`} />
            </span>
            <div className={`absolute bg-white shadow rounded top-14 w-full h-[200px] overflow-y-scroll ${activeContainer === 0 ? '' : 'hidden'} fade-in__left`} ref={(ref) => (containerRef.current[0] = ref)}>
                {
                    isEmpty(tiers) ?
                        <p className='flex items-center justify-start w-full gap-2 p-2 text-sm font-medium leading-none text-gray-500 cursor-pointer hover:bg-grey lg:p-4 last:rounded-b first:rounded-t'>
                            <img src={alert} alt='Up' loading='lazy' className={`w-8`} />
                            No stores pricing tiers available
                        </p>
                        :
                        tiers?.map((tier) => (
                            <p className='flex items-center justify-start w-full gap-2 p-2 text-sm font-medium leading-none text-gray-500 cursor-pointer hover:bg-grey lg:p-4 last:rounded-b first:rounded-t' key={tier?.idx} onClick={() => assignTier(tier)}>
                                {`${tier?.description}`}
                            </p>
                        ))
                }
            </div>
        </div>
    )
}
