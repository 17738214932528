//images
import emptyIcon from '../../../../../assets/icons/empty.svg';

export const EmptyStore = ({ content }) => {
    return (
        <div className='relative flex flex-wrap items-center justify-around w-full h-full gap-2'>
            {[...Array(18)].map((_, index) => (
                <div key={index} className={`w-[48%] mx-auto sm:w-[32%] md:w-[18%] lg:w-[16%] h-[360px] shadow-2xl bg-white rounded-lg p-2 cursor-pointer flex flex-col justify-start gap-2`}>
                    <div className="w-full h-[200px] rounded-lg border flex justify-center items-center bg-black-skeleton animate-pulse animate-ping"></div>
                    <div className="h-[140px] w-full flex flex-col justify-between">
                        <div className="flex flex-col justify-start gap-1 md:gap-2 bg">
                            <p className="w-10/12 p-3 rounded bg-black-skeleton animate-pulse animate-ping"></p>
                            <p className="w-6/12 p-2 rounded bg-black-skeleton animate-pulse animate-ping"></p>
                        </div>
                        <div className="flex items-center justify-between">
                            <p className="w-2/12 p-3 rounded bg-black-skeleton animate-pulse animate-ping"></p>
                            <p className="flex items-center justify-end gap-2 font-medium text-gray-500">
                                SOH:
                                <span className="p-3 rounded bg-black-skeleton animate-pulse animate-ping"></span>
                            </p>
                        </div>
                        <div className="flex items-center justify-between w-full">
                            <p className="flex items-center justify-start gap-2 text-xl font-medium md:text-2xl">
                                R
                                <span className="px-6 py-3 rounded bg-black-skeleton animate-pulse animate-ping"></span>
                            </p>
                            <span className="px-6 py-4 rounded bg-black-skeleton animate-pulse animate-ping"></span>
                        </div>
                    </div>
                    <div className='h-[50px] flex items-start justify-between gap-1'>
                        <div className='flex flex-col items-center justify-start w-3/12 gap-1'>
                            <span className='w-full h-1 rounded-lg bg-red'></span>
                            <p className='text-sm font-medium leading-none text-center text-gray-500'>7 Days</p>
                            <p className='px-6 py-2 rounded bg-black-skeleton animate-pulse animate-ping'></p>
                        </div>
                        <div className='flex flex-col items-center justify-start w-3/12 gap-1'>
                            <span className='w-full h-1 rounded-lg bg-yellow'></span>
                            <p className='text-sm font-medium leading-none text-center text-gray-500'>14 Days</p>
                            <p className='px-6 py-2 rounded bg-black-skeleton animate-pulse animate-ping'></p>
                        </div>
                        <div className='flex flex-col items-center justify-start w-3/12 gap-1'>
                            <span className='w-full h-1 rounded-lg bg-green'></span>
                            <p className='text-sm font-medium leading-none text-center text-gray-500'>21 Days</p>
                            <p className='px-6 py-2 rounded bg-black-skeleton animate-pulse animate-ping'></p>
                        </div>
                    </div>
                </div>
            ))}
            <div className='fixed flex items-center justify-center w-11/12 gap-4 p-6 text-white rounded-lg bg-green md:6/12 lg:w-6/12 xl:4/12'>
                <figure className='flex items-center justify-center'>
                    <img src={emptyIcon} alt='Empty Store' loading='lazy' className='object-contain w-20 md:w-28' />
                </figure>
                <p className='font-medium leading-5 text-md'>{content}</p>
            </div>
        </div>
    )
}
