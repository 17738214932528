import { useState, useRef, useContext } from 'react'
import { isEmpty } from 'lodash'
import { toast } from 'react-hot-toast';

//images
import bchev from '../../assets/icons/chevup.svg'
import alert from '../../assets/icons/alert.svg'
import lightingIcon from '../../assets/icons/idea.svg'
import searchIcon from '../../assets/icons/search.svg'

//hooks
import { useQuery } from '../../hooks/useQuery'

//components
import { CartPopUp } from './create-new/cart-pop-up'
import { Products } from './create-new/products'
import { StoresFilter } from './create-new/store-dropdown'
import { InputHandler } from '../../hooks/useInputHandler'

//icon mapper
import { IconMapper } from '../../utils/app-settings'
import axios from 'axios'
import { CartContext } from '../../context/cart/cartContext';

export const CreateNew = () => {
    const [state, setState] = useState({
        category: 'All Categories'
    })
    const [activeContainer, setActiveContainer] = useState(null);
    const containerRef = useRef([]);

    const cartContext = useContext(CartContext);
    const { addToCart } = cartContext;

    const { inputValues, clearValues, handleChange } = InputHandler();

    const { data } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/createorder/categories`, 'get');

    const toggleContainer = (index) => activeContainer === index ? setActiveContainer(null) : setActiveContainer(index);

    const toggleDropDown = (index) => toggleContainer(index)

    const closeDropDown = () => setActiveContainer(null)

    const assignCategory = (category) => {
        setState({ ...state, category: category })
        closeDropDown()
    }

    const clearFilter = () => {
        setState({ ...state, category: 'All Categories' });
        clearValues();
    };

    const useSmartOrder = async () => {
        try {
            toast(`Please wait while we generate your smart order`, {
                icon: '⏳',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 5000,
            });

            const session = JSON.parse(sessionStorage.getItem('session'));

            const config = {
                headers: {
                    'token': session?.token,
                    mode: 'no-cors'
                }
            };

            const orderItems = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/v1/order/suggestedorder/${session?.uid}`, config)

            if (orderItems?.data && !isEmpty(orderItems?.data)) {

                toast(`Adding products to your cart, this will take a few seconds....`, {
                    icon: '📦',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 6000,
                });

                for (const product of orderItems?.data) {
                    addToCart(product)
                }
            }
            else {
                toast('You have no frequently ordered items to add to your cart', {
                    icon: '🚨',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });
            }
        }
        catch (error) {
            toast('Failed to generate your smart order, please try again', {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }
    };

    return (
        <div className="flex flex-col justify-start w-full h-full gap-2 lg:gap-4">
            <div className="flex flex-wrap items-center justify-between w-full gap-2 lg:justify-start">
                <div className='flex items-center justify-between w-full gap-4 lg:w-4/12'>
                    <StoresFilter />
                    <div className="relative z-10 flex flex-col justify-start w-1/2 gap-4">
                        <span className="flex items-center justify-between w-full p-3 text-sm font-medium text-center text-gray-500 bg-white border rounded shadow cursor-pointer" onClick={() => toggleDropDown(0)}>
                            <span className='flex items-center justify-start gap-2'>
                                <span className="text-xs font-medium text-left text-gray-500 lg:text-sm">
                                    {state.category}
                                </span>
                            </span>
                            <img src={bchev} alt='Up' loading='lazy' className={`w-5 ${activeContainer === 0 && 'rotate-180'} lg:ease-in-out duration-300`} />
                        </span>
                        <div className={`absolute bg-white shadow rounded top-14 w-full h-[400px] overflow-y-scroll ${activeContainer === 0 ? '' : 'hidden'} fade-in__left`} ref={(ref) => (containerRef.current[0] = ref)}>
                            {
                                data && isEmpty(data) ?
                                    <p className="flex items-center justify-start gap-2 p-4">
                                        <img src={alert} alt='Up' loading='lazy' className={`w-5 lg:w-10`} />
                                        <span className="text-xs text-left lg:text-base">No categories available</span>
                                    </p>
                                    : data && data.map((category, index) => (
                                        <p className='flex items-center justify-start w-full gap-2 p-2 text-xs font-medium leading-none text-gray-500 cursor-pointer hover:bg-grey lg:p-4 last:rounded-b first:rounded-t lg:text-sm' key={index} onClick={() => assignCategory(category?.description)}>
                                            {
                                                IconMapper?.map((icon) =>
                                                    icon?.name === category.description &&
                                                    <img src={icon?.icon} alt='Up' loading='lazy' className={`w-5`} />
                                                )
                                            }
                                            {category?.description}
                                        </p>
                                    ))
                            }
                        </div>
                    </div>
                </div>
                <div className='flex items-end justify-start w-full gap-2 lg:w-1/2 lg:items-center'>
                    <div className='relative flex items-center justify-between w-3/4 lg:w-1/2'>
                        <input
                            id='searchInput'
                            type='search'
                            placeholder="search"
                            className="w-full rounded shadow"
                            name='searchTerm'
                            value={inputValues.searchTerm || ''}
                            onChange={handleChange}
                        />
                        <button className='absolute hidden px-6 py-2 text-sm font-medium text-white uppercase duration-300 rounded-r cursor-pointer right-2 bg-red hover:mr-1 lg:ease-in-out lg:block' onClick={clearFilter}>Clear</button>
                        <img src={searchIcon} alt='Up' loading='lazy' className={`w-6 flex lg:hidden absolute right-2`} onClick={clearFilter} />
                    </div>
                    <div className='w-1/4'>
                        <figure className='flex items-center justify-center w-16 h-10 p-2 rounded cursor-pointer bg-green lg:w-20' onClick={useSmartOrder} >
                            <img src={lightingIcon} alt='Up' loading='lazy' className={`w-6 cursor-pointer`} />
                        </figure>
                    </div>
                </div>
            </div>
            <div className='w-full h-[79vh] lg:h-[77.5vh] flex justify-start gap-x-2 gap-y-3 md:gap-y-4 flex-wrap pr-[2px] pb-16 overflow-y-scroll overflow-x-hidden relative'>
                <CartPopUp />
                <Products params={{ category: inputValues.searchTerm ? 'ALL CATEGORIES' : state.category, searchTerm: inputValues && inputValues.searchTerm }} />
            </div>
        </div >
    )
}

