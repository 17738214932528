import { useTempHistory } from "./useTempHistory";
import loaderIcon from '../../../assets/icons/splashloader.svg';

export const TempHistory = () => {
    const data = useTempHistory();

    if (!data || data.length === 0) {
        return (
            <div className='flex items-center justify-center w-full mb-4'>
                <img src={loaderIcon} alt='Loading' className="w-12 animate-spin" />
            </div>
        )
    }

    const tableData = data?.map((property) => {

        return {
            uid: property.client_uid,
            frierID: property.machine,
            firstAve: property.first_avg ? property.first_avg : '--:--',
            secondAve: property.second_avg ? property.second_avg : '--:--',
            thirdAve: property.third_avg ? property.third_avg : '--:--',
            date: property.date?.slice(0, 10)
        }
    })

    return (
        <div className="z-40 flex flex-col justify-start w-full gap-2 overflow-y-scroll bg-white rounded rounded-b shadow">
            <div className="flex flex-col justify-start w-full gap-1 p-1 rounded bg-white h-[200px] overflow-y-scroll">
                {tableData?.map((item, index) =>
                    <>
                        <div className="flex items-center justify-between w-full gap-1 duration-300 rounded lg:ease-in-out lg:rounded lg:cursor-pointer lg:hover:bg-black-skeleton" key={index}>
                            <div className="flex items-center justify-between w-full p-1" >
                                <div className="w-[25%] flex items-center justify-between pl-[72px]">
                                    <p className="text-sm font-medium text-gray-500">{item?.date}</p>
                                </div>
                                <div className="w-[25%] flex items-center justify-between pl-[72px]">
                                    <p className="text-sm font-medium text-purple">Frier {item?.frierID}</p>
                                </div>
                                <div className="w-[25%] flex flex-col justify-center items-center">
                                    <p className="text-sm font-medium text-gray-500">07:00 - 09:00 hrs</p>
                                    <p className={`text-base font-medium text-gray-500 ${item?.firstAve > 180 || String(item?.firstAve)?.includes('--:--') ? 'text-red' : 'text-green'}`}>{item?.firstAve} °C</p>
                                </div>
                                <div className="w-[25%] flex flex-col justify-center items-center">
                                    <p className="text-sm font-medium text-gray-500">11:00 - 14:00 hrs</p>
                                    <p className={`text-base font-medium text-gray-500 ${item?.secondAve > 180 || String(item?.secondAve)?.includes('--:--') ? 'text-red' : 'text-green'}`}>{item?.secondAve} °C</p>
                                </div>
                                <div className="w-[25%] flex flex-col justify-center items-center">
                                    <p className="text-sm font-medium text-gray-500">16:00 - 19:00 hrs</p>
                                    <p className={`text-base font-medium text-gray-500 ${item?.thirdAve > 180 || String(item?.thirdAve)?.includes('--:--') ? 'text-red' : 'text-green'}`}>{item?.thirdAve} °C</p>
                                </div>
                            </div>
                        </div>
                        <hr className="w-[95%] mx-auto border-[0.5px] border-grey-light" />
                    </>
                )}
            </div>
        </div>
    )
}