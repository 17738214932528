import { useContext, useState } from 'react';

//components
import { Layout } from '../layout/layout'

//context providers
import { CartContext } from '../context/cart/cartContext';

//images
import cartgadd from '../assets/icons/grayadd.svg';
import cartremove from '../assets/icons/grayremove.svg';
import placeholder from '../assets/icons/placeholder.svg';
import rtimes from '../assets/icons/rtimes.svg';
import minus from '../assets/icons/faEyeSlash.svg';
import plus from '../assets/icons/faEye.svg';
import wloader from '../assets/icons/wloader.svg'
import deleteIcon from '../assets/icons/rdelete.svg'


export const Cart = () => {
    const [state, setState] = useState({
        isHidden: '',
        isOpen: false
    })
    const [activeTab, setActiveTab] = useState(1);

    const cartContext = useContext(CartContext);
    const {
        cartItems,
        removeFromCart,
        cartTotal,
        incrementItem,
        decrementItem,
        editCartItem,
        checkout,
        clearCart,
        checkoutStatus,
    } = cartContext;

    const handleIncrement = (itemId) => incrementItem(itemId)

    const handleDecrement = (itemId) => decrementItem(itemId);

    const handleQuantityChange = (event, itemId) => {
        const { value } = event.target;

        const parsedValue = parseInt(value, 10);

        if (!isNaN(parsedValue) && parsedValue >= 0) {
            editCartItem(itemId, parsedValue);
        }
    };

    const toggleVisibility = () => setState({ ...state, isHidden: !state.isHidden });

    return (
        <Layout>
            <div className='w-full h-[93vh] md:h-[90vh] flex flex-col md:flex-row items-start justify-between md:gap-3 overflow-y-scroll md:overflow-hidden p-2 bg-grey lg:ease-in-out duration-500'>
                <div className={`${state.isHidden && 'hidden md:flex'} h-[40%] md:h-full w-full md:w-8/12 flex flex-col justify-start gap-4 p-2`}>
                    <div className='flex items-end justify-between w-full'>
                        <p className='flex items-center justify-between pb-1 text-xl font-medium text-gray-500 border-b-2 border-purple'>
                            <span>Order Summary</span>
                            <img src={state.isHidden ? plus : minus} alt='Hide/Show items' loading='lazy' className='cursor-pointer w-7 md:hidden' onClick={toggleVisibility} />
                        </p>
                        <div className=''>
                            <img src={deleteIcon} alt='' loading='lazy' className='w-8 cursor-pointer' onClick={clearCart} />
                        </div>
                    </div>
                    <div className={`${state.isHidden ? 'hidden' : 'h-full overflow-y-scroll flex flex-col justify-start gap-2 rounded cursor-pointer py-2 pr-1'} lg:ease-in-out duration-500`}>
                        {
                            cartItems?.map((product) =>
                                <div className='flex items-start justify-between w-full p-2 bg-white rounded' key={product.uid}>
                                    <div className='flex items-center justify-center w-4/12 h-full p-2 rounded bg-grey'>
                                        <img
                                            src={`${process.env.REACT_APP_IMAGE_ENDPOINT}/${product.uid}.jpg`}
                                            onError={(e) => {
                                                e.target.onError = null;
                                                e.target.src = placeholder;
                                            }}
                                            alt=''
                                            loading='lazy' className='object-contain w-20 h-20' />
                                    </div>
                                    <div className='flex flex-col justify-start w-6/12 gap-2'>
                                        <p className='text-sm font-medium leading-none'>{product.name}</p>
                                        <p className='text-sm text-gray-500 leading-1'>{product.description && product.description.substring(0, 30)}...</p>
                                        <div className='flex items-center justify-start gap-2'>
                                            <button className='cursor-pointer' onClick={() => handleDecrement(product.uid)}>
                                                <img src={cartremove} alt='Remove' loading='lazy' className='w-6' />
                                            </button>
                                            <input
                                                type='text'
                                                name='quantity'
                                                placeholder={product.quantity}
                                                value={product.quantity}
                                                className='flex items-center justify-center text-center w-[50px] h-[30px] border-[1px] hover:border-purple text-sm'
                                                onInput={(e) => handleQuantityChange(e, product.uid)}
                                            />
                                            <button className='cursor-pointer' onClick={() => handleIncrement(product.uid)}>
                                                <img src={cartgadd} alt='Add' loading='lazy' className='w-6' />
                                            </button>
                                        </div>
                                        <p className='font-medium text-gray-500'>R{Number(product.incsell * product.quantity).toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                                        <p className={`text-sm ${Number(product.One) ? 'text-gray-500' : 'text-red'} font-medium`}>{Number(product.One) ? `Stock for ${(Number(product.quantity) / Number(product.One)).toFixed(2)} days` : 'No data'}</p>
                                    </div>
                                    <div className='w-1/12 h-full'>
                                        <figure className='flex items-center justify-center h-full'>
                                            <img src={rtimes} alt='' loading='lazy' className='w-10 cursor-pointer' onClick={() => removeFromCart(product.uid)} />
                                        </figure>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className={`${state.isHidden ? 'h-full' : 'h-[60%]'} md:h-full w-full md:w-4/12 flex flex-col justify-between gap-2 p-2 rounded lg:ease-in-out duration-500 bg-white`}>
                    <div className='w-full'>
                        <p className='flex items-center justify-between pb-1 text-xl font-medium text-gray-500 border-b-2 border-purple'>
                            <span>Order Checkout</span>
                            <img src={state.isHidden ? plus : minus} alt='Hide/Show items' loading='lazy' className={`cursor-pointer w-7 md:hidden ${!state.isHidden && 'hidden'}`} onClick={toggleVisibility} />
                        </p>
                    </div>
                    <div className='h-[78%] flex flex-col justify-start gap-2 md:gap-4'>
                        <div className='flex items-center justify-between w-full gap-2 p-2'>
                            <button className={`text-white rounded cursor-pointer p-2 w-1/3 hover:bg-purple font-medium lg:ease-in-out duration-500 md:text-sm ${activeTab === 2 ? 'bg-purple' : 'bg-black'}`} onClick={() => setActiveTab(2)}>Save As A Draft</button>
                            <button className={`text-white rounded cursor-pointer p-2 w-1/3 hover:bg-purple font-medium lg:ease-in-out duration-500 md:text-sm ${activeTab === 1 ? 'bg-purple' : 'bg-black'}`} onClick={() => setActiveTab(1)}>Place An Order</button>
                            <button className={`text-white rounded cursor-pointer p-2 w-1/3 hover:bg-purple font-medium lg:ease-in-out duration-500 md:text-sm ${activeTab === 0 ? 'bg-purple' : 'bg-black'}`} onClick={() => setActiveTab(0)}>Instant Checkout</button>
                        </div>
                        {activeTab === 0 &&
                            <div className='flex items-center justify-center w-full h-full'>
                                <div className='w-10/12 py-6 text-center text-white rounded bg-green'>
                                    <p className='text-xl'>Payment gateway</p>
                                    <p className='text-xl'>coming soon!</p>
                                </div>
                            </div>
                        }
                        {activeTab === 1 &&
                            <div className='flex items-center justify-center w-full h-full'>
                                <div className='w-10/12 py-6 text-center text-white rounded bg-green'>
                                    <p>You are placing this order to your DC</p>
                                </div>
                            </div>
                        }
                        {activeTab === 2 &&
                            <div className='flex items-center justify-center w-full h-full'>    
                                <div className='w-10/12 py-6 text-center text-white rounded bg-green'>
                                    <p>You are saving this order as a DRAFT</p>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={`flex flex-col justify-end gap-3 px-2 py-2 rounded bg-grey shadow-inner ${state.isHidden ? 'h-[15%]' : 'h-[30%] md:h-[20%]'}`}>
                        <div className='flex items-center justify-between w-full'>
                            <p className='text-xl font-medium text-gray-500'>Cart Total</p>
                            <p className='flex justify-end px-2 py-1 text-xl font-medium text-left text-black rounded bg-yellow lg:text-lg'>R {Number(cartTotal).toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                        </div>
                        <div className='flex items-center justify-between'>
                            <button className={`text-white rounded py-3 hover:text-white hover:bg-green w-full font-medium uppercase text-sm ${activeTab === 0 ? 'bg-red cursor-not-allowed hover:bg-red' : 'bg-purple'}`} disabled={activeTab === 3 || activeTab === 0} onClick={() => checkout(activeTab === 0 ? 2 : activeTab === 1 ? 0 : 1)}>
                                {checkoutStatus === true ?
                                    <span className='flex items-center justify-center gap-2'>
                                        Processing
                                        <img src={wloader} alt='' loading='lazy' className={`w-6 animate-spin`} />
                                    </span>
                                    : activeTab === 0 ? 'Coming Soon...' : activeTab === 1 ? 'Place Your Order' : activeTab === 2 ? 'Save As A Draft' : 'Select A Payment Method'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}