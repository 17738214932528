import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { useState, useEffect } from "react";

//components
import Table from '../../components/tables/orders-table';
import { Portal } from '../../components/portals/approval-modal';

//hooks
import { useQuery } from "../../hooks/useQuery";
import { InputHandler } from "../../hooks/useInputHandler";
import { useSessionPolling } from '../../hooks/useSessionPolling';

//configurations 
import { tableHeaders } from '../../utils/app-settings';

//images
import done from '../../assets/icons/done.svg'
import hold from '../../assets/icons/hold.svg'
import cancel from '../../assets/icons/cancel.svg'
import pdf from '../../assets/icons/picking_pdf.svg'
import previous from '../../assets/icons/redo.svg'

//error handling components
import { Empty, Loading, Error } from '../../components/states/orders/states';

//helper functions
import {
    approveToLoading,
    cancelOrder,
    pauseOrder,
    revertToStock,
    generatePS
} from "../../helpers/table/table-helpers";

export const Picking = () => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        action: '',
        isVisible: null,
        invoice: '',
        sendSMS: false,
        index: ''
    });

    const headers = tableHeaders[0]?.approvals[3]?.picking;
    const sizing = tableHeaders[0]?.approvals[3]?.sizing;

    let getIndex = useSessionPolling('tabIndex')

    useEffect(() => {
        setState({ index: getIndex + 1 })
    }, [getIndex]);


    const { inputValues, clearValues, handleChange } = InputHandler();

    const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/OrderStage/picking`, 'get');

    if (isLoading) {
        return (
            <Loading sizing={sizing} header={headers} />
        )
    }

    if (error) {
        window.location.reload();
        return (
            <Error content={'We could not load your orders'} sizing={sizing} header={headers} />
        )
    }

    if (isEmpty(data)) {
        return (
            <Empty content={'You have no orders in picking'} sizing={sizing} header={headers} />
        )
    }

    const handleCheckbox = () => setState((prevState) => ({ ...prevState, sendSMS: !prevState.sendSMS }));

    const confirmAction = (property, action) => setState({ ...state, action, isVisible: true, invoice: property });

    const processAssigner = () => {
        const { action, invoice, sendSMS, index } = state;

        switch (action) {
            case 'cancel':
                cancelOrder(invoice, sendSMS, index);
                break;
            case 'prevStage':
                revertToStock(invoice, sendSMS);
                break;
            case 'pause':
                pauseOrder(invoice, sendSMS, index);
                break;
            case 'approve':
                approveToLoading(invoice, sendSMS);
                break;
            default:
                break;
        }

        closeModal();
    };

    const closeModal = () => setState((prevState) => ({ ...prevState, isVisible: false, sendSMS: false }));

    const searchResult = data.filter((item) => {
        for (const key in item) {
            if (item[key]?.toString()?.toLowerCase()?.includes(inputValues?.searchTerm?.toLocaleLowerCase())) {
                return true;
            }
        }
        return false;
    });

    const searchInvoices = searchResult?.map((property) => {
        const amountNumber = parseFloat(property.amount);
        const formattedAmount = amountNumber.toLocaleString(undefined, { minimumFractionDigits: 2 });

        return {
            order_number: property.uid,
            order_date: property.creation_date.replace(/T.*/, ''),
            client: property.company_name,
            amount: !isNaN(amountNumber) ? 'R ' + formattedAmount : 'R 0.00',
            status: property.status,
            action: (
                <div className="flex items-center justify-center gap-2">
                    <img src={cancel} alt='Cancel' loading="lazy" className="w-7 cursor-pointer" onClick={() => confirmAction(property, 'cancel')} />
                    <img src={previous} alt='On Hold' loading="lazy" className="w-7 cursor-pointer" onClick={() => confirmAction(property, 'prevStage')} />
                    <img src={hold} alt='On Hold' loading="lazy" className="w-7 cursor-pointer" onClick={() => confirmAction(property, 'pause')} />
                    <img src={done} alt='Done' loading="lazy" className="w-7 cursor-pointer" onClick={() => confirmAction(property, 'approve')} />
                </div>
            ),
            integration: (
                <div className="flex items-center justify-center gap-2" key={Math.random()}>
                    <img src={pdf} alt='Done' loading="lazy" className="w-7 cursor-pointer" onClick={() => generatePS(property)} />
                </div>
            ),
        };
    });

    const defaultPicking = data?.map((property) => {
        const amountNumber = parseFloat(property.amount);
        const formattedAmount = amountNumber.toLocaleString(undefined, { minimumFractionDigits: 2 });

        return {
            order_number: property.uid,
            order_date: property.creation_date.replace(/T.*/, ''),
            client: property.company_name,
            amount: !isNaN(amountNumber) ? 'R ' + formattedAmount : 'R 0.00',
            status: property.status,
            action: (
                <div className="flex items-center justify-center gap-2">
                    <img src={cancel} alt='Cancel' loading="lazy" className="w-7 cursor-pointer" onClick={() => confirmAction(property, 'cancel')} />
                    <img src={previous} alt='On Hold' loading="lazy" className="w-7 cursor-pointer" onClick={() => confirmAction(property, 'prevStage')} />
                    <img src={hold} alt='On Hold' loading="lazy" className="w-7 cursor-pointer" onClick={() => confirmAction(property, 'pause')} />
                    <img src={done} alt='Done' loading="lazy" className="w-7 cursor-pointer" onClick={() => confirmAction(property, 'approve')} />
                </div>
            ),
            integration: (
                <div className="flex items-center justify-center gap-2" key={Math.random()}>
                    <img src={pdf} alt='Done' loading="lazy" className="w-11 cursor-pointer" onClick={() => generatePS(property)} />
                </div>
            ),
        };
    });

    const picking = !isEmpty(searchInvoices) ? searchInvoices : defaultPicking

    const handleInvoiceClick = (invoice) => navigate(`/invoice/${invoice?.order_number}`)

    return (
        <div className="w-full h-full flex flex-col justify-start gap-3">
            <div className="w-full flex flex-col-reverse lg:flex-row lg:items-center flex-wrap justify-start gap-2">
                <div className="w-full md:w-3/4 lg:w-1/3 relative flex items-center justify-between lg:ease-in-out duration-300">
                    <input
                        type='text'
                        placeholder="search"
                        name="searchTerm"
                        value={inputValues.searchTerm || ''}
                        onChange={handleChange}
                        className="w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow" />
                    <button className='absolute right-2 bg-red rounded-e text-white text-sm font-medium py-1 md:py-2 px-6 cursor-pointer hover:mr-1' onClick={clearValues}>Clear</button>
                </div>
                <span className="text-base font-medium text-gray-500">Picking: {picking.length}</span>
            </div>
            <div className="w-full h-[82vh] md:h-[82vh] lg:h-[70.2vh] xl:h-[74vh] overflow-y-hidden relative">
                {state.isVisible && <Portal content={{ action: state.action, invoiceNo: state.invoice }} closeModal={closeModal} handleCheckbox={handleCheckbox} processAssigner={processAssigner} />}
                <Table headers={headers} data={picking} onInvoiceClick={handleInvoiceClick} sizing={sizing} />
            </div>
        </div>
    )
}
