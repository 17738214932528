import { UseDateEvaluator, UseTimeEvaluator } from "../../../helpers/time-evaluator";

export const UseTableData = (opentime, closetime) => {
    const mergedData = {};

    if (opentime && closetime) {

        opentime?.forEach((item) => {
            const { shopname, ...rest } = item;
            mergedData[shopname] = { ...mergedData[shopname], ...rest };
        });

        closetime?.forEach((item) => {
            const { shopname, ...rest } = item;
            mergedData[shopname] = { ...mergedData[shopname], ...rest };
        });
    }

    const resultArray = Object.keys(mergedData)?.map((shopname) => ({ shopname, ...mergedData[shopname] }));

    const iotData = resultArray?.map((property) => {

        const openingTimeDiff = opentime ? UseDateEvaluator('07:00:00', property?.opentime?.slice(10, 19)?.replace('T', '')) : 0
        const closingTimeDiff = closetime ? UseDateEvaluator('19:00:00', property?.closetime?.slice(10, 19)?.replace('T', '')) : 0

        return {
            shopName: property?.shopname,
            doorName: property?.doorname,

            //closing times calculations
            closeTime: property?.closetime?.slice(10, 19)?.replace('T', ' '),
            closingDiff: UseTimeEvaluator(Number(String(closingTimeDiff)?.replace(/[^0-9]/g, ''))),
            closeStatus: closingTimeDiff >= 1 ? 'Late' : closingTimeDiff === 0 ? `On-Time` : 'Early',

            //opening times calculations
            openTime: property?.opentime?.slice(10, 19)?.replace('T', ' '),
            openingDiff: UseTimeEvaluator(Number(String(openingTimeDiff)?.replace(/[^0-9]/g, ''))),
            openStatus: openingTimeDiff >= 1 ? 'Late' : openingTimeDiff === 0 ? `On-Time` : 'Early',
        }
    })

    return iotData;
}
