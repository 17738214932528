import axios from 'axios';
import { useState, useEffect } from 'react';

const parseSettingData = (setting) => {
    const { uid, enabled } = setting;

    return { uid, enabled };
};

export const SOHSettings = () => {
    const [data, setData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = JSON.parse(sessionStorage.getItem('session'));

                const config = {
                    headers: {
                        'token': user?.token,
                    },
                };

                const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/v1/settings/getsohsetting`, config);

                if (response.status === 200) {
                    const sohSettingData = response.data.map(parseSettingData);

                    setData(sohSettingData);
                }
            } catch (error) { }
        };

        fetchData();
    }, []);

    if (!data) {
        return null;
    }

    return data;
};
