import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

//components
import Table from '../../components/tables/orders-table';

//hooks
import { useQuery } from "../../hooks/useQuery";
import { InputHandler } from "../../hooks/useInputHandler";

//configurations 
import { tableHeaders } from '../../utils/app-settings';

//error handling components
import { Empty, Loading, Error } from '../../components/states/orders/states';

export const Drafts = () => {
    const navigate = useNavigate();

    const headers = tableHeaders[0]?.orders[0]?.drafts;
    const sizing = tableHeaders[0]?.orders[0]?.sizing;

    const { inputValues, clearValues, handleChange } = InputHandler();

    const user = JSON.parse(sessionStorage.getItem('session'));

    const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/${user?.type?.toLowerCase()?.includes('super admin') || user?.type?.toLowerCase()?.includes('dc user') ? 'api/v1/order/draft' : `api/v1/storeuser/getalldraftsforstoreuser/${user?.uid}`}`, 'get', 10000);

    if (isLoading) {
        return (
            <Loading sizing={sizing} header={headers} />
        )
    }

    if (error) {
        window.location.reload();
        return (
            <Error content={'We could not load your drafts'} sizing={sizing} header={headers} />
        )
    }

    if (isEmpty(data)) {
        return (
            <Empty content={'You have no drafts'} sizing={sizing} header={headers} />
        )
    }

    const searchResult = data.filter((item) => {
        for (const key in item) {
            if (item[key]?.toString()?.toLowerCase()?.includes(inputValues?.searchTerm?.toLocaleLowerCase())) {
                return true;
            }
        }
        return false;
    });

    const searchInvoices = searchResult?.map((property) => {
        const amountNumber = parseFloat(property.amount);
        const formattedAmount = 'R ' + amountNumber.toLocaleString(undefined, { minimumFractionDigits: 2 });

        return {
            operation: property.dc_name,
            order_number: property.number,
            order_date: property.creation_date.replace(/T.*/, ''),
            client: property.company_name,
            amount: !isNaN(amountNumber) ? formattedAmount : 'R 0.00',
        };
    });

    const defaultDrafts = data?.map((property) => {
        const amountNumber = parseFloat(property.amount);
        const formattedAmount = 'R ' + amountNumber.toLocaleString(undefined, { minimumFractionDigits: 2 });

        return {
            operation: property.dc_name,
            order_number: property.number,
            order_date: property.creation_date.replace(/T.*/, ''),
            client: property.company_name,
            amount: !isNaN(amountNumber) ? formattedAmount : 'R 0.00',
        };
    });

    const drafts = !isEmpty(searchInvoices) ? searchInvoices : defaultDrafts

    const handleInvoiceClick = (invoice) => navigate(`invoice/${invoice?.order_number}`)

    return (
        <div className="flex flex-col justify-start w-full h-full gap-3">
            <div className="flex flex-col-reverse flex-wrap justify-start w-full gap-2 lg:flex-row lg:items-center">
                <div className="relative flex items-center justify-between w-full duration-300 md:w-3/4 lg:w-1/3 lg:ease-in-out">
                    <input
                        type='text'
                        placeholder="search"
                        name="searchTerm"
                        value={inputValues.searchTerm || ''}
                        onChange={handleChange}
                        className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm" />
                    <button className='absolute px-6 py-1 text-sm font-medium text-white cursor-pointer right-2 bg-red rounded-e md:py-2 hover:mr-1' onClick={clearValues}>Clear</button>
                </div>
                <span className="text-base font-medium text-gray-500">Drafts: {drafts.length}</span>
            </div>
            <div className="w-full h-[80vh] md:h-[82vh] lg:h-[74.5vh] overflow-y-hidden">
                <Table headers={headers} data={drafts} onInvoiceClick={handleInvoiceClick} sizing={sizing} />
            </div>
        </div>
    )
}