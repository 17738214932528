import { useState } from "react"
import { Layout } from "../layout/layout"
import { MapTile } from "../modules/map/map-tile"
import { LocateVehicle } from "../helpers/map/sections/locate-vehicle"
import { VehicleHistory } from "../helpers/map/sections/vehicle-history"
import { TrackSalesRep } from "../helpers/map/sections/track-rep"
import { TrackOrders } from "../helpers/map/sections/track-invoice"
import { useVehicleContext } from "../helpers/map/context/map-context"

export const Map = () => {
    const [state, setState] = useState({
        currentAction: 0
    })

    const { clearCanvas } = useVehicleContext();

    const assignAction = async (action) => setState({ ...state, currentAction: action })

    const clearMapCanvas = () => {
        clearCanvas()
        setState({ ...state, currentAction: 0 })

        window.location.reload()
    }

    const renderSections = () => {
        const sections = {
            1: <VehicleHistory />,
            2: <LocateVehicle />,
            3: <TrackOrders />,
            4: <TrackSalesRep />,
        };

        const inView = sections[state.currentAction];

        return inView ? inView : null;
    }

    return (
        <Layout>
            <section className="relative w-full h-screen overflow-hidden">
                <div className="absolute left-14 z-[9999] right-0 top-3 w-full lg:w-8/12 flex flex-col items-start justify-start gap-2">
                    <div className="flex flex-wrap items-center justify-center w-full gap-4 lg:justify-start">
                        <button className="w-1/3 p-2 text-sm font-medium text-white bg-gray-500 rounded cursor-pointer lg:w-2/12 opacity-90 hover:bg-purple lg:ease-in-out lg:duration-300" onClick={() => assignAction(1)}>Vehicle History</button>
                        <button className="w-1/3 p-2 text-sm font-medium text-white bg-gray-500 rounded cursor-pointer lg:w-2/12 opacity-90 hover:bg-purple lg:ease-in-out lg:duration-300" onClick={() => assignAction(2)}>Vehicle Location</button>
                        <button className="w-1/3 p-2 text-sm font-medium text-white bg-gray-500 rounded cursor-pointer lg:w-2/12 opacity-90 hover:bg-purple lg:ease-in-out lg:duration-300" onClick={() => assignAction(3)}>Track Order</button>
                        <button className="w-1/3 p-2 text-sm font-medium text-white bg-gray-500 rounded cursor-pointer lg:w-2/12 opacity-90 hover:bg-purple lg:ease-in-out lg:duration-300" onClick={() => assignAction(4)}>Track Sales Reps</button>
                        <button className="w-1/3 p-2 text-sm font-medium text-white rounded cursor-pointer lg:w-2/12 bg-red opacity-90" onClick={clearMapCanvas}>Clear</button>
                    </div>
                    <div className="w-full">
                        {renderSections()}
                    </div>
                </div>
                <MapTile />
            </section>
        </Layout>
    )
}
