import { isEmpty } from 'lodash';
import toast from 'react-hot-toast';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from '../../../hooks/useQuery';
import { CartContext } from '../../../context/cart/cartContext';
import { EmptyStore, LoadingStore, StoreError } from './states';
import { SOHSettings } from '../../../helpers/order/getSOHSetting';

import addCart from '../../../assets/icons/grayadd.svg';
import cartgadd from '../../../assets/icons/grayadd.svg';
import promo from '../../../assets/icons/promo-banner.svg';
import cartTick from '../../../assets/icons/ticksquare.svg';
import cartremove from '../../../assets/icons/grayremove.svg';
import placeholder from '../../../assets/icons/placeholder.svg';

export const ProductNotFound = () => {
    return (
        <div className='flex items-center justify-center w-full h-full p-2 border'>
            <p className='flex items-center justify-center w-full h-56 mx-auto text-3xl font-semibold text-center text-white rounded lg:w-1/2 bg-red'>Product Not Found</p>
        </div>
    )
}

export const ProductSOH = ({ stock }) => {
    const sohSetting = SOHSettings();

    const user = JSON.parse(sessionStorage.getItem('session'));

    if (!sohSetting) {
        return;
    }

    const { enabled } = sohSetting?.[0]

    const { type } = user

    const status = enabled === 1 && (type?.toLowerCase()?.includes('store user'))

    return status && <p className={`text-gray-500 font-medium ${stock <= 0 && 'text-red'} text-xs`}> {stock >= 1 ? `DC SOH: ${stock}` : 'Out of Stock'}</p>
}

export const searchProducts = (productsArray, searchTerm) => {

    const matchingProducts = productsArray?.filter((product) => {

        const propertyValues = Object?.values(product)?.map((value) =>
            typeof value === 'string' ? value?.toLowerCase() : value
        );

        return propertyValues?.some(
            (value) => typeof value === 'string' && value?.includes(searchTerm?.toLowerCase())
        );
    });

    return matchingProducts;
};

export const Products = ({ params }) => {
    console.log(params)
    const cartContext = useContext(CartContext);

    const user = JSON.parse(sessionStorage.getItem('session'));

    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const { cartItems, addToCart, store, editCartItem, incrementItem, decrementItem } = cartContext;

    const cardHeight = (store?.uid && store?.tier_uid) ? '[300px]' : '[200px]'

    const handleIncrement = (itemId) => incrementItem(itemId);

    const handleDecrement = (itemId) => decrementItem(itemId);

    const getCartItemQuantity = (uid) => {
        if (uid) {
            const cartItem = cartItems?.find((item) => item.uid === uid);

            return cartItem ? cartItem?.quantity : 0;
        }
        return 0;
    };

    useEffect(() => {
        getCartItemQuantity()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const cartCheck = (product) => {
        if (store) {
            setButtonDisabled(true);

            addToCart(product)

            setTimeout(() => {
                setButtonDisabled(false);
            }, 20);
        }
        else {
            toast('Please select a store first', {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }
    };

    const handleQuantityChange = (event, itemId) => {
        const { value } = event.target;

        const parsedValue = parseInt(value, 10);

        if (!isNaN(parsedValue) && parsedValue >= 0) {
            editCartItem(itemId, parsedValue);
        }
    };

    //stores are not ready for this feature - active this after adding autoGRVs
    // const productROS = (product) => {
    //     if (store?.uid) {

    //         const { Min, Average, Max } = product
    //         const { ROS_Min, ROS_Average, ROS_Max } = user

    //         return (
    //             <div className='h-[50px] flex items-start justify-between gap-1'>
    //                 <div className='flex flex-col items-center justify-start w-3/12 gap-1'>
    //                     <span className='w-full h-1 rounded-lg bg-red'></span>
    //                     <p className='text-sm font-medium leading-none text-center text-gray-500'>{ROS_Min} Days</p>
    //                     <p className='text-sm font-medium leading-none text-center text-gray-500'>{Min >= 0 ? Min : 0}</p>
    //                 </div>
    //                 <div className='flex flex-col items-center justify-start w-3/12 gap-1'>
    //                     <span className='w-full h-1 rounded-lg bg-yellow'></span>
    //                     <p className='text-sm font-medium leading-none text-center text-gray-500'>{ROS_Average} Days</p>
    //                     <p className='text-sm font-medium leading-none text-center text-gray-500'>{Average >= 0 ? Average : 0}</p>
    //                 </div>
    //                 <div className='flex flex-col items-center justify-start w-3/12 gap-1'>
    //                     <span className='w-full h-1 rounded-lg bg-green'></span>
    //                     <p className='text-sm font-medium leading-none text-center text-gray-500'>{ROS_Max} Days</p>
    //                     <p className='text-sm font-medium leading-none text-center text-gray-500'>{Max >= 0 ? Max : 0}</p>
    //                 </div>
    //             </div>
    //         )
    //     }
    //     else {
    //         return;
    //     }
    // }

    const productPricing = (product) => {
        if (store?.tier_uid) {
            return (
                <div className='flex items-center justify-between w-full'>
                    <p className='text-xl font-medium'>R {product?.special === 1 ? product?.special_price?.toLocaleString(undefined, { minimumFractionDigits: 2 }) : product?.incsell?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                    {cartItems.some(item => item.uid === product?.uid) ?
                        <div className='flex items-center justify-start gap-1'>
                            <button className='cursor-pointer' onClick={() => handleDecrement(product?.uid)}>
                                <img src={cartremove} alt={`Remove ${product?.name}`} loading='lazy' className='w-6' />
                            </button>
                            <input
                                className='text-black border-green lg:ease-in-out duration-300 flex items-center justify-center text-center w-12 border-[1px] hover:border-green focus:outline-green outline-none text-sm placeholder:not-italic placeholder:text-black'
                                type='text'
                                name='quantity'
                                value={getCartItemQuantity(product?.uid)}
                                onInput={(e) => handleQuantityChange(e, product?.uid)}
                            />
                            <button className='w-6 cursor-pointer' onClick={() => handleIncrement(product?.uid)}>
                                <img src={cartgadd} alt={`Add ${product?.name}`} loading='lazy' className='w-full' />
                            </button>
                        </div>
                        :
                        <button onClick={() => cartCheck(product)} disabled={isButtonDisabled} className='duration-300 cursor-pointer lg:ease-in-out'>
                            <img src={cartItems.some(item => item.uid === product?.uid) ? cartTick : addCart} alt='Add to cart' loading='lazy' className={`${cartItems.some(item => item.uid === product.uid) && 'opacity-30'} w-10 lg:ease-in-out duration-300 cursor-pointer`} />
                        </button>
                    }
                </div>
            )
        }
        else {
            return;
        }
    }

    const productSpecial = (product) => {

        if (product?.special === 1) {
            return (
                <figure className='absolute top-0 right-0'>
                    <img src={promo} alt='On Promotion' loading='lazy' className='w-28' />
                </figure>
            )
        }
        else {
            return;
        }
    }

    // add for tier list prices
    //const url = store?.tier_uid ? `items/itemstierlist/${store?.tier_uid}` : 'items';
    const url = 'items';
    const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/${url}`, 'get', 200);
    

    if (isLoading) {
        return (
            <LoadingStore />
        );
    }

    if (error) {
        return (
            <StoreError />
        )
    }

    if (isEmpty(data)) {
        return (
            <EmptyStore content={'The store seems empty please refresh'} />
        )
    }

    const searchedProduct = params?.searchTerm ? searchProducts(data, params?.searchTerm) : params?.category ? searchProducts(data, params?.category) : data

    const products = (params?.searchTerm && !isEmpty(searchedProduct)) ? searchedProduct : (params?.category && !isEmpty(searchedProduct)) ? searchedProduct : data;
  
    if (!Array.isArray(products)) {
        return <ProductNotFound />;
    }

    return (
        <>
            {products?.map((product, index) => (
                <div className={`w-[48%] mx-auto md:w-[24%] lg:w-[16%] h-${cardHeight} shadow-2xl rounded p-2 cursor-pointer flex flex-col justify-start gap-2 relative bg-white lg:ease-in-out lg:duration-500`} key={index}>
                    {productSpecial(product)}
                    <div className={`w-full h-1/2 rounded-lg border-2 flex justify-center items-center`}>
                        <figure className='flex items-center justify-center w-full h-full'>
                            <img
                                src={`${process.env.REACT_APP_IMAGE_ENDPOINT}/${product.uid}.jpg`}
                                onError={(e) => {
                                    e.target.onError = null;
                                    e.target.src = placeholder;
                                }}
                                alt={product.name}
                                loading='lazy' className='object-contain w-full h-full' />
                        </figure>
                    </div>
                    <div className='h-[140px] w-full flex flex-col justify-between'>
                        <div className='flex flex-col justify-start gap-1 md:gap-2'>
                            <p className='font-medium leading-none text-gray-700 text-md'>{product.name}</p>
                            <p className='text-sm font-medium leading-none text-gray-500 uppercase'>{product.description}</p>
                        </div>
                        <div className='flex items-center justify-between'>
                            <p className='text-sm font-medium text-gray-500'>{product.packsize}</p>
                            {(user?.type?.toLowerCase()?.includes('super admin') || user?.type?.toLowerCase()?.includes('dc user')) && <p className={`text-gray-500 font-medium ${product.stock <= 0 && 'text-red'}`}> {product.stock >= 1 ? `DC SOH: ${product.stock}` : 'Out of Stock'}</p>}
                            <ProductSOH product={product} />
                        </div>
                        {productPricing(product)}
                    </div>
                    {/* //stores are not ready for this feature - active this after adding autoGRVs */}
                    {/* {productROS(product)} */}
                </div >
            ))}
        </>
    );
};