import React, { useState, useRef } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import {
    Label,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

//hooks
import { useQuery } from '../../../../hooks/useQuery';
import { InputHandler } from '../../../../hooks/useInputHandler';

//images	
import filterIcon from '../../../../assets/icons/filter.svg'
import closeIcon from '../../../../assets/icons/times.svg'
import alert from '../../../../assets/icons/alert.svg'
import emptyIcon from '../../../../assets/icons/empty.svg'

//configuration
import { config } from '../../../../utils/app-settings';

export const OrdersPerStore = () => {
    const [state, setState] = useState({
        pageWidth: '',
        searchResults: ''
    })
    const [activeContainer, setActiveContainer] = useState(null);
    const containerRef = useRef([]);
    const { inputValues, handleChange } = InputHandler();

    const primaryDates = {
        start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
    }

    const findData = async () => {
        try {
            const getFilterResults = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/v1/analytics/ordersperstore/${inputValues?.start_date?.slice(0, 10)}/${inputValues?.end_date?.slice(0, 10)}`, config)

            if (getFilterResults?.data && !isEmpty(getFilterResults?.data)) {
                setState({ ...state, searchResults: getFilterResults?.data })

                toast(`Filtering completed successfully`, {
                    icon: '👋',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                closeFilter()
            }
            else if (isEmpty(getFilterResults?.data)) {
                toast('There is no data between the selected timeframe', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                closeFilter()
            }
            else {
                toast('Failed to fetch the data, please refresh', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                closeFilter()
            }
        }
        catch (error) {
            toast('Failed to fetch the data, please refresh', {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }
    }

    const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/analytics/ordersperstore/${primaryDates?.start_date}/${primaryDates?.end_date}`, "get");

    if (isLoading) {
        return (
            <div className="bg-white p-2 h-[400px] w-full rounded-lg shadow border-2 flex items-center justify-center text-sm text-gray-500 font-medium relative">
                <div className='w-full h-full flex flex-col justify-center items-center gap-2'>
                    <span className='p-3 animate-pulse animate-ping w-3/4 rounded bg-black-skeleton'></span>
                    <span className='p-3 animate-pulse animate-ping w-[15px] rounded bg-black-skeleton absolute top-8 right-7 hidden md:flex'></span>
                    <div className='w-full h-[260px] flex items-center justify-between gap-1 pr-5'>
                        <div className='w-[3%] h-full bg-black-skeleton animate-ping animate-pulse rounded hidden md:flex'></div>
                        <div className='w-full md:w-[95%] h-full bg-black-skeleton animate-ping animate-pulse rounded'></div>
                    </div>
                    <span className='p-3 animate-pulse animate-ping w-1/4 rounded bg-black-skeleton'></span>
                </div>
            </div>
        )
    }

    if (error) {
        window.location.reload();
        return (
            <div className="bg-white p-2 h-[400px] w-full rounded-lg shadow border-2 flex items-center justify-center text-sm text-gray-500 font-medium relative">
                <div className='w-full h-full flex flex-col justify-center items-center gap-2'>
                    <span className='p-3 animate-pulse animate-ping w-3/4 rounded bg-black-skeleton'></span>
                    <span className='p-3 animate-pulse animate-ping w-[15px] rounded bg-black-skeleton absolute top-8 right-7 hidden md:flex'></span>
                    <div className='w-full h-[260px] flex items-center justify-between gap-1 pr-5'>
                        <div className='w-[3%] h-full bg-black-skeleton animate-ping animate-pulse rounded hidden md:flex'></div>
                        <div className='w-full md:w-[95%] h-full bg-black-skeleton rounded relative flex flex-col justify-center items-center'>
                            <div className='bg-white p-2 rounded flex items-center justify-start gap-2'>
                                <img src={alert} loading='lazy' alt='Filter' className='w-10' />
                                <p>We could not load this chart's data</p>
                            </div>
                        </div>
                    </div>
                    <span className='p-3 animate-pulse animate-ping w-1/4 rounded bg-black-skeleton'></span>
                </div>
            </div>
        )
    }

    if (isEmpty(data)) {
        return (
            <div className="bg-white p-2 h-[400px] w-full rounded-lg shadow border-2 flex items-center justify-center text-sm text-gray-500 font-medium relative">
                <div className='w-full h-full flex flex-col justify-center items-center gap-2'>
                    <span className='p-3 animate-pulse animate-ping w-3/4 rounded bg-black-skeleton'></span>
                    <span className='p-3 animate-pulse animate-ping w-[15px] rounded bg-black-skeleton absolute top-8 right-7 hidden md:flex'></span>
                    <div className='w-full h-[260px] flex items-center justify-between gap-1 pr-5'>
                        <div className='w-[3%] h-full bg-black-skeleton animate-ping animate-pulse rounded hidden md:flex'></div>
                        <div className='w-full md:w-[95%] h-full bg-black-skeleton rounded relative flex flex-col justify-center items-center'>
                            <div className='bg-green text-white p-2 rounded flex items-center justify-start gap-2'>
                                <img src={emptyIcon} loading='lazy' alt='Filter' className='w-10' />
                                <p>There is no data for this chart</p>
                            </div>
                        </div>
                    </div>
                    <span className='p-3 animate-pulse animate-ping w-1/4 rounded bg-black-skeleton'></span>
                </div>
            </div>
        )
    }

    //preps data to be mapped into the chart
    const groupedData = state.searchResults ? state.searchResults : data

    const chartData = groupedData?.map((property) => {
        const shortenedName = property.company_name.replace(/store|outlet|plus/gi, '').trim();

        return {
            stage: shortenedName,
            'Stores': property?.Amount
        };
    });

    const toggleContainer = (index) => activeContainer === index ? setActiveContainer(null) : setActiveContainer(index);
    const toggleFilter = (index) => toggleContainer(index)

    const closeFilter = () => setActiveContainer(null);

    const ItalizeLabels = (props) => {
        const { x, y, payload } = props;
        return (
            <g transform={`translate(${x}, ${y})`} >
                <text x={0} y={0} dy={16} textAnchor="end" transform="rotate(-15)" className='text-xs'>
                    {payload?.value}
                </text>
            </g>
        );
    };

    return (
        <div className="bg-white p-2 h-[500px] w-full rounded-lg shadow border-2 flex items-center justify-center text-sm text-gray-500 font-medium relative">
            <div className='w-full h-full flex flex-col justify-center items-center gap-2'>
                <h2 className='text-center text-base md:text-lg'>Orders Per Store: All Time <span className='text-base font-normal'>(Qty)</span></h2>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={500}
                        height={300}
                        data={chartData}
                        margin={{
                            top: 30,
                            right: 10,
                            left: 10,
                            bottom: 30,
                        }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="stage" tick={<ItalizeLabels />} allowDuplicatedCategory={true} interval={0} />
                        <YAxis tick={{ fontSize: 12 }}>
                            <Label value="Quantity" angle={-90} position="insideLeft" style={{ textAnchor: 'middle' }} />
                        </YAxis>
                        <Tooltip
                            content={({ payload, label, active }) => {
                                if (active) {
                                    const value = payload[0]?.value || 0;
                                    const lineColor = payload[0]?.stroke || 'var(--blue)'; // Default color if stroke is not provided

                                    return (
                                        <div className="bg-white border border-grey-darker p-2 rounded shadow max-w-xs">
                                            <p className="label">{`${label}`}</p>
                                            <div className="quantity">
                                                <p style={{ color: lineColor }}>
                                                    {`Orders: ${value}`}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                }

                                return null;
                            }}
                        />
                        <Legend />
                        <Line type="monotone" dataKey="Stores" stroke="var(--blue)" strokeWidth={2} dot={{ r: 5 }} activeDot={{ r: 5 }} />
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <button className='absolute top-3 md:top-8 right-3 md:right-7 cursor-pointer flex items-end gap-2' onClick={() => toggleFilter(0)}>
                {state.searchResults ? moment(inputValues?.start_date?.slice(0, 10)).format('MMMM YYYY') : moment(primaryDates?.start_date).format('MMMM YYYY')}
                <img src={filterIcon} loading='lazy' alt='Filter' className='w-6' />
            </button>
            <div className={`absolute w-full h-full bg-black-light rounded-lg flex items-center justify-center ${activeContainer === 0 ? '' : 'hidden'} fade-in `} ref={(ref) => (containerRef.current[0] = ref)}>
                <div className='bg-white w-6/12 rounded-lg flex items-end justify-center gap-4 p-3 relative flex-wrap'>
                    <button className='absolute top-1 right-1 cursor-pointer' onClick={closeFilter}>
                        <img src={closeIcon} loading='lazy' alt='Filter' className='w-7' />
                    </button>
                    <div className='w-full md:w-4/12 flex flex-col gap-1 lg:ease-in-out duration-500 mt-8'>
                        <label className='font-medium text-base text-gray-500'>Start Date</label>
                        <input
                            name="start_date"
                            type='datetime-local'
                            className='border border-grey  bg-white focus:outline-none rounded p-2  text-gray-500 font-medium hover:border-purple lg:ease-in-out duration-300 outline-none placeholder:italic text-sm shadow w-full'
                            placeholder='2023-05-05'
                            value={inputValues.start_date || ''}
                            onChange={handleChange}
                        />
                    </div>
                    {
                        inputValues?.start_date &&
                        <div className='w-full md:w-4/12 flex flex-col gap-1 lg:ease-in-out duration-500'>
                            <label className='font-medium text-base text-gray-500'>End Date</label>
                            <input
                                name="end_date"
                                type='datetime-local'
                                className='border border-grey bg-white focus:outline-none rounded p-2  text-gray-500 font-medium hover:border-purple lg:ease-in-out duration-300 outline-none placeholder:italic text-sm shadow w-full'
                                placeholder='2023-05-05'
                                value={inputValues.end_date || ''}
                                onChange={handleChange}
                            />
                        </div>
                    }
                    {
                        (inputValues?.start_date?.slice(0, 10) && inputValues?.end_date?.slice(0, 10)) && <button className='flex flex-col gap-1 lg:ease-in-out duration-500 bg-purple text-white text-sm  shadow font-medium py-2 px-8 rounded tracking-wide' onClick={findData}>Filter</button>
                    }
                </div>
            </div>
        </div>
    );
};
