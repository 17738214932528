import axios from 'axios';
import { useState, useEffect } from 'react';

export const useHours = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = JSON.parse(sessionStorage.getItem('session'));

                const config = {
                    headers: {
                        'token': user?.token,
                    },
                };

                const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/v1/iot/getopenclose`, config);

                if (response.status === 200) {

                    setData(response?.data)

                }
            } catch (error) { }
        };

        fetchData();
    }, []);

    if (data.length === 0) {
        return null;
    }

    return data;
};