import axios from "axios";
import { toast } from "react-hot-toast";

export const AssignFrierType = async (uid, frierType) => {
    try {
        const user = JSON.parse(sessionStorage.getItem('session'));

        const config = {
            headers: {
                'token': user?.token,
                mode: 'no-cors'
            }
        };

        const response = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/iot/updatefryertype/${frierType}/${uid}`, config)

        if (response?.data?.message === 'success') {
            toast(`Standard type set successfully`, {
                icon: '✅',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }
    }
    catch (error) {
        toast(`${error?.message}, please retry`, {
            icon: '✅',
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
            duration: 3000,
        });
    }
}
