import axios from 'axios';
import { useState, useEffect } from 'react';

const parseClientData = (client) => {
    const {
        uid,
        colour_code: color,
        latitude,
        longitude,
        company_name: storeName,
        store_type: storeType,
        business_street_address,
        business_address_complex,
        business_address_province,
        business_address_city,
        business_address_country,
        company_contact_number: contact_number,
        company_contact_email: email,
    } = client;

    const address = `${business_street_address || ''} ${business_address_complex || ''} ${business_address_province || ''} ${business_address_city || ''} ${business_address_country || ''}`;

    return {
        uid,
        color,
        latitude,
        longitude,
        storeName,
        storeType,
        address,
        contact_number,
        email,
    };
};

export const AllClients = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = JSON.parse(sessionStorage.getItem('session'));

                const config = {
                    headers: {
                        'token': user?.token,
                    },
                };

                const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/v1/client`, config);

                if (response.status === 200) {
                    const clientsData = response.data.map(parseClientData);
                    setData(clientsData);
                }
            } catch (error) { }
        };

        fetchData();
    }, []);

    if (data.length === 0) {
        return null;
    }

    return data;
};
