import { useState } from "react";
import { isEmpty } from "lodash";
import { Layout } from "../../layout/layout";
import { useHours } from "./helpers/useHours";
import { UseTableData } from "./helpers/table-data";
import { FilterHistory } from "./helpers/useHoursHistory";
import { InvoiceTableHeaders } from "../../utils/app-settings";
import { WorkHoursHistory } from "./helpers/work-hours-history";

export const WorkingHours = () => {
    const data = useHours();
    const [isOpen, setIsOpen] = useState(false);

    const toggleHistory = () => setIsOpen(!isOpen);

    const mainHeaders = InvoiceTableHeaders[0]?.approvals[6]?.iot;

    const iotData = UseTableData(data?.closetime, data?.opentime);

    if (isEmpty(iotData)) {
        return (
            <Layout>
                <div className="h-[89.5vh] p-2 flex flex-col justify-center gap-2 relative items-center">
                    <div className="flex flex-col justify-start w-full gap-3 p-3 bg-white rounded">
                        <div className="flex items-center justify-between w-full gap-2">
                            <div className="flex items-center justify-end w-1/2 rounded lg:pr-20">
                                <div className="w-10/12 p-2 text-center rounded bg-green">
                                    <p className="text-white">Opening Time</p>
                                </div>
                            </div>
                            <div className="flex items-center justify-center w-1/2 rounded">
                                <div className="w-9/12 p-2 text-center rounded lg:-mr-6 bg-red">
                                    <p className="text-white">Closing Time</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-between w-full">
                            {mainHeaders?.map((title, index) => (
                                <div className={`w-[11%] flex items-center justify-center`} key={index}>
                                    <p className={`text-sm font-medium text-center text-gray-500 w-full`}>{title}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="h-[91vh] overflow-y-scroll flex flex-col justify-start gap-2 pr-1 pb-4 w-full">
                        {[...Array(50)].map((_, index) => (
                            <div key={index} className="flex items-center justify-between w-full gap-2 p-1 text-black bg-white cursor-wait">
                                {[...Array(9)].map((_, index) => <div className="w-[10%] bg-black-skeleton animate-pulse p-4 rounded"></div>)}
                            </div>
                        ))}
                    </div>
                    <div className="absolute w-4/12 p-4 rounded bg-red">
                        <p className="text-center text-white">No Data Available</p>
                    </div>
                </div>
            </Layout>
        )
    }

    return (
        <Layout>
            <div className="h-[89.5vh] p-2 flex flex-col justify-start gap-2">
                <div className="flex flex-col justify-start w-full gap-3 p-3 bg-white rounded">
                    <div className="flex items-center justify-between gap-2">
                        <div className="flex items-center justify-end w-1/2 rounded lg:pr-20">
                            <div className="w-10/12 p-2 text-center rounded bg-green">
                                <p className="text-white">Opening Time</p>
                            </div>
                        </div>
                        <div className="flex items-center justify-center w-1/2 rounded">
                            <div className="w-9/12 p-2 text-center rounded lg:-mr-6 bg-purple">
                                <p className="text-white">Closing Time</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-between w-full">
                        {mainHeaders?.map((title, index) => (
                            <div className={`w-[11%] flex items-center justify-center`} key={index}>
                                <p className={`text-sm font-medium text-center text-gray-500 w-full`}>{title}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="h-[91vh] overflow-y-scroll flex flex-col justify-start gap-2 pr-1 pb-4">
                    {iotData?.map((iot) => (
                        <div className="flex flex-col justify-start gap-0 rounded shadow cursor-pointer bg-grey" key={iot?.uid}>
                            <div className={`flex items-center justify-between w-full p-3 text-gray-500 border rounded shadow-sm cursor-pointer relative bg-white`} >
                                <div className={`w-[10%] flex items-center justify-center gap-2`}>
                                    <p className={`text-sm font-medium text-center ${isOpen ? "text-green" : "text-purple"}`} onClick={toggleHistory}>{iot?.shopName ? iot?.shopName : "--:--"}</p>
                                    {isOpen && <FilterHistory />}
                                </div>
                                <div className={`w-[10%] flex items-center justify-center`}>
                                    <p className={`text-sm font-medium text-center w-full`}>07:00</p>
                                </div>
                                <div className={`w-[10%] flex items-center justify-center`}>
                                    <p className={`text-sm font-medium text-center w-full ${!iot?.openTime && "text-red font-bold"}`}>{iot?.openTime ? iot?.openTime : "--:--"}</p>
                                </div>
                                <div className={`w-[10%] flex items-center justify-center`}>
                                    <p className={`text-sm font-medium text-center w-full ${iot?.openStatus?.toLocaleLowerCase()?.includes("late") || iot?.openStatus === 0 ? "text-red" : "text-green"}`}>{`${iot?.openingDiff?.hours} hrs ${iot?.openingDiff?.minutes} mins`}</p>
                                </div>
                                <div className={`w-[10%] flex items-center justify-center ${iot?.openStatus?.toLocaleLowerCase()?.includes("late") || iot?.openStatus === 0 ? "bg-red" : "bg-green"} rounded p-1`}>
                                    <p className={`font-medium text-center w-full text-white`}>{iot?.openStatus}</p>
                                </div>
                                <div className={`w-[10%] flex items-center justify-center`}>
                                    <p className={`text-sm font-medium text-center w-full`}>18:00</p>
                                </div>
                                <div className={`w-[10%] flex items-center justify-center`}>
                                    <p className={`text-sm font-medium text-center w-full ${!iot?.closeTime && "text-red font-bold"}`}>{iot?.closeTime ? iot?.closeTime : "--:--"}</p>
                                </div>
                                <div className={`w-[10%] flex items-center justify-center`}>
                                    <p className={`text-sm font-medium text-center w-full ${iot?.closeStatus?.toLocaleLowerCase()?.includes("late") || iot?.closeStatus === 0 ? "text-red" : "text-green"}`}>{`${iot?.closingDiff?.hours} hrs ${iot?.closingDiff?.minutes} mins`}</p>
                                </div>
                                <div className={`w-[10%] flex items-center justify-center ${iot?.closeStatus?.toLocaleLowerCase()?.includes("late") || iot?.closeStatus === 0 ? "bg-red" : "bg-green"} rounded p-1`}>
                                    <p className={`font-medium text-center w-full text-white`}>{iot?.closeStatus}</p>
                                </div>
                            </div>
                            {isOpen && <WorkHoursHistory />}
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    );
};
