import moment from "moment";

export const UseTimeEvaluator = (totalMinutes) => {
    if (totalMinutes < 0 || !Number.isInteger(totalMinutes)) {
        throw new Error("Input must be a non-negative integer.");
    }

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return { hours, minutes };
};

export const UseDateEvaluator = (startTime, endTime) => {

    const startMoment = moment(startTime, 'HH:mm:ss');
    const endMoment = moment(endTime, 'HH:mm:ss');

    const differenceInSeconds = endMoment.diff(startMoment, 'seconds');

    const differenceInMinutes = Math.ceil(differenceInSeconds / 60);

    return differenceInMinutes;
};