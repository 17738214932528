import axios from 'axios';
import { useEffect, useState } from 'react';

export const useQuery = (url, method = 'get', batchSize = 200) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const FetchData = async () => {
            const token = JSON.parse(sessionStorage.getItem('session'))?.token;
            const config = { headers: { 'token': token } };

            try {
                let response;
                let batchData = [];
                let hasMoreData = true;

                while (hasMoreData) {
                    switch (method.toLowerCase()) {
                        case 'get':
                            response = await axios.get(url, config);
                            break;
                        case 'post':
                            response = await axios.post(url, config);
                            break;
                        case 'put':
                            response = await axios.put(url, config);
                            break;
                        case 'delete':
                            response = await axios.delete(url, config);
                            break;
                        default:
                            response = await axios.get(url, config);
                            break;
                    }

                    batchData = batchData.concat(response.data);

                    if (response.data.length < batchSize) {
                        hasMoreData = false;
                    }
                }

                setData(batchData);

            } catch (err) {
                setError(err);
            }
            setIsLoading(false);
        };

        FetchData();

        const intervalId = setInterval(() => {
            FetchData();
        }, 1700);

        return () => {
            clearInterval(intervalId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, method, batchSize]);

    return { data, isLoading, error };
};