import { useState } from "react";
import { Layout } from "../../layout/layout";
import { InvoiceTableHeaders } from "../../utils/app-settings";

import { useHours } from "./helpers/useHours";
import { useTemps } from "./helpers/useTemps";
import { UseTableData } from "./helpers/table-data";
import feather from '../../assets/icons/feather.svg';
import { InputHandler } from "../../hooks/useInputHandler";
import { AssignFrierType } from "./helpers/assign-frier-temps";
import { TempHistory } from "./helpers/temp-history";

export const FrierTemperatures = () => {
    const data = useHours();
    const temp = useTemps();
    const [isOpen, setIsOpen] = useState(false);

    const toggleHistory = () => setIsOpen(!isOpen);

    const [isEditing, setIsEditing] = useState(0);

    const { inputValues, handleChange } = InputHandler();

    const tempHeaders = InvoiceTableHeaders[0]?.approvals[6]?.iottemps;

    const closeToggle = () => setIsEditing(0);
    const toggleEdit = (ref) => setIsEditing(ref);

    const assignSTDTemp = (uid) => {
        if (inputValues.frierSTDTemp && uid) {
            AssignFrierType(uid, inputValues.frierSTDTemp)
        }
    };

    const assignFrierType = (uid) => {
        if (inputValues.frierType && uid) {
            AssignFrierType(uid, inputValues.frierType)
        }
    };

    const mergeDataArrays = (closeTimes, TempsData) => {
        const mergedData = {};

        if (closeTimes && Array.isArray(closeTimes) && TempsData && Array.isArray(TempsData)) {
            closeTimes?.forEach((item) => {
                const { shopname, ...rest } = item;
                mergedData[shopname] = { ...mergedData[shopname], ...rest };
            });

            TempsData?.forEach((item) => {
                const { shopname, ...rest } = item;
                mergedData[shopname] = { ...mergedData[shopname], ...rest };
            });
        }

        const resultArray = Object.keys(mergedData)?.map((uid) => ({ uid, ...mergedData[uid] }))

        const storeIOTData = resultArray?.map((property) => {

            return {
                shopName: property?.shopName,
                openTime: property.openTime,
                closeTime: property.closeTime,
                temp: property.temp,
                targetTemp:
                    <div className="flex items-center justify-center gap-1">
                        {
                            isEditing === 1 ?
                                <div className="flex flex-col justify-start gap-1">
                                    <p className="text-sm text-left">Set Standard Temp</p>
                                    <div className="flex items-center justify-between gap-1">
                                        <div className="relative flex items-center justify-between">
                                            <input
                                                className="text-sm font-medium text-gray-500 rounded placeholder:text-xs"
                                                type='number'
                                                name="frierSTDTemp"
                                                value={inputValues.frierSTDTemp || ''}
                                                onChange={handleChange}
                                                placeholder="180"
                                            />
                                            <button className="absolute px-4 py-1 text-white rounded outline-none right-2 bg-green" onClick={() => assignSTDTemp(property.client_uid)}>✓</button>
                                        </div>
                                        <button className="px-2 py-1 text-white rounded outline-none cursor-pointer right-2 bg-red" onClick={closeToggle}>X</button>
                                    </div>
                                </div>
                                :
                                <>
                                    <p>{property?.target_temp}</p>
                                    <img src={feather} alt='' className="w-6 cursor-pointer" onClick={() => toggleEdit(1)} />
                                </>
                        }
                    </div>,
                uid: property.client_uid,
                type:
                    <div className="flex items-center justify-center gap-1">
                        {
                            isEditing === 2 ?
                                <div className="flex flex-col justify-start gap-1">
                                    <p className="text-sm text-left">Set Frier Type</p>
                                    <div className="flex items-center justify-between gap-1">
                                        <div className="relative flex items-center justify-between">
                                            <input
                                                className="text-sm font-medium text-gray-500 rounded placeholder:text-xs"
                                                type='text'
                                                name="frierType"
                                                value={inputValues.frierType || ''}
                                                onChange={handleChange}
                                                placeholder="Gas"
                                            />
                                            <button className="absolute px-4 py-1 text-white rounded outline-none right-2 bg-green" onClick={() => assignFrierType(property.client_uid)}>✓</button>
                                        </div>
                                        <button className="px-2 py-1 text-white rounded outline-none cursor-pointer right-2 bg-red" onClick={closeToggle}>X</button>
                                    </div>
                                </div>
                                :
                                <>
                                    <p>{property?.fryer_type}</p>
                                    <img src={feather} alt='' className="w-6 cursor-pointer" onClick={() => toggleEdit(2)} />
                                </>
                        }
                    </div>,
            }
        })

        return storeIOTData;
    };

    const iotData = UseTableData(data?.closetime, data?.opentime);
    const IOTData = mergeDataArrays(iotData, temp);

    return (
        <Layout>
            <div className="h-[89.5vh] p-2 flex flex-col justify-start gap-2">
                <div className="flex flex-col justify-start w-full gap-3 p-3 bg-white rounded">
                    <div className="flex items-center justify-between w-full">
                        {tempHeaders?.map((title, index) => <div className={`w-[11%] flex items-center justify-center`} key={index}><p className={`text-sm font-medium text-center text-gray-500 w-full`} >{title}</p></div>)}
                    </div>
                </div>
                <div className="h-[91vh] overflow-y-scroll flex flex-col justify-start gap-2 pr-1 pb-4">
                    {IOTData?.map((iot, index) =>
                        <div className="flex flex-col justify-start gap-1 bg-white rounded shadow cursor-pointer" key={index} onClick={toggleHistory}>
                            <div className="flex items-center justify-start px-[70px] py-2">
                                <p className={`text-sm font-medium text-left text-gray-500`}>
                                    
                                    <span className="text-xl text-purple">Malvern</span>
                                </p>
                            </div>
                            <div className="flex items-center justify-between w-full p-2">
                                <div className={`w-[11%] flex items-center justify-center gap-0`}>
                                    <p className={`text-sm font-medium text-center text-purple w-full`} >Frier {index + 1}</p>
                                </div>
                                <div className={`w-[11%] flex items-center justify-center`}>
                                    <p className={`text-sm font-medium text-center w-full text-gray-500`} >{iot?.targetTemp}</p>
                                </div>
                                <div className={`w-[11%] flex items-center justify-center`}>
                                    <p className={`text-sm font-medium text-center text-gray-500 w-full`} >{iot?.temp ? iot?.temp : '--:--'}</p>
                                </div>
                                <div className={`w-[11%] flex items-center justify-center`}>
                                    <p className={`text-sm font-medium text-center text-gray-500 w-full ${iot?.temp > 180 ? "text-red" : 'text-green'}`} >{iot?.temp ? String((180 - iot?.temp))?.replace('-', '') : '--:--'}</p>
                                </div>
                                <div className={`w-[11%] flex items-center justify-center ${iot?.temp > 180 ? 'bg-red' : 'bg-green'} p-1 rounded`}>
                                    <p className={`font-medium text-center w-full text-white`} >{iot?.temp > 180 ? 'Too Hot' : 'Normal'}</p>
                                </div>
                                <div className={`w-[11%] flex items-center justify-end`}>
                                    <div className={`text-sm font-medium text-center flex justify-center items-center text-gray-500 w-full`} >{iot?.type ? iot?.type : '--:--'}</div>
                                </div>
                            </div>
                            {isOpen &&
                                <>
                                    <div className="lg:px-20">
                                        <p className="pb-2 text-xl font-medium text-gray-500 border-b border-grey-darker">Average Temp History</p>
                                    </div>
                                    <TempHistory />
                                </>
                            }
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    )
}