import { isEmpty } from "lodash";
import { useState } from "react";
import { toast } from 'react-hot-toast';

//hooks
import { InputHandler } from "../../hooks/useInputHandler";

//components
import { Layout } from '../../layout/layout';
import { AddressAutocomplete } from "../users/sections/address-autocomplete";
import { Roles } from "./../users/sections/roles";
import { StoreTypes } from "./sections/store-types";

//images
import axios from "axios";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import loadingIcon from "../../assets/icons/wloader.svg";
import { useQuery } from "../../hooks/useQuery";

//components
import { Error, IsEmpty, IsLoading } from "./sections/states";
import { DeliveryDays } from "./sections/delivery-days";
import { PriceList } from "./sections/price-list";

export const EditClient = () => {
    const [state, setState] = useState({
        role: '',
        division: '',
        userType: '',
        assignedStores: '',
        isLoading: '',
        errorID: '',
        errorUsername: '',
        businessAdress: [],
        shippingAdress: [],
        operationCompleted: '',
        status: null
    })

    const navigate = useNavigate();

    const { inputValues, clearValues, handleChange } = InputHandler();;

    const { query } = useParams();

    const handleAssignedTiers = (params) => setState({ ...state, tier: params });

    const handleDeliveryDates = (params) => setState({ ...state, deliveryDay: params });

    const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/client/${query}`, "get");

    if (isLoading) {
        return (
            <IsLoading />
        )
    }

    if (error) {
        return (
            <Error />
        )
    }

    if (isEmpty(data)) {
        return (
            <IsEmpty />
        )
    }

    const goBack = () => navigate(-1);

    const toggleStatus = () => setState((prevState) => ({ ...prevState, status: prevState.status === 0 ? 1 : 0 }));

    //grabs and sets values from drop down menus
    const handleRoles = (params) => setState({ ...state, role: params })
    const handleAssignedStores = (params) => setState({ ...state, assignedStores: params })

    const handleAddress = async (address) => isEmpty(state.businessAdress) ? setState({ ...state, businessAdress: address }) : setState({ ...state, shippingAdress: address })

    const editUser = async (e) => {
        e.preventDefault()

        try {
            setState({ ...state, isLoading: true, })

            const clientData = {
                uid: inputValues?.userID || data[0]?.uid,
                company_name: inputValues?.companyName || data[0]?.company_name,
                registered_name: inputValues?.regCompanyName || data[0]?.registered_name,
                vat_number: inputValues?.vatNumber || data[0]?.vat_number,
                active: state.status !== 'null' ? state.status : data[0]?.active !== null ? data[0]?.active : 0,
                last_order_date: moment().format('YYYY-MM-DD') || data[0]?.last_order_date,
                approved_credit_limit: inputValues?.creditLimit || data[0]?.approved_credit_limit,
                credit_limit_warning: inputValues?.creditWarning || data[0]?.credit_limit_warning,
                latitude: state.businessAdress?.coords?.lat || data[0]?.latitude,
                longitude: state.businessAdress?.coords?.lng || data[0]?.longitude,
                store_type: state.assignedStores?.uid || data[0]?.store_type,
                delivery_target_time: inputValues?.dTime || data[0]?.delivery_target_time,
                business_street_address: state.businessAdress?.fullAddress?.STREET || data[0]?.business_street_address,

                tier_uid: state.tier?.idx,
                del_preference: state.deliveryDay,

                // fields left blank are not used in the app
                business_address_complex: inputValues?.complexName,
                business_address_province: state.businessAdress?.fullAddress?.PROVINCE,
                business_address_suburb: state.businessAdress?.fullAddress?.SUBURB || data[0]?.business_address_suburb,
                business_address_city: state.businessAdress?.fullAddress?.CITY || data[0]?.business_address_city,
                business_address_postal_code: state.businessAdress?.postalCode || data[0]?.business_address_postal_code,
                business_address_reg_number: inputValues?.regNumber || data[0]?.business_address_reg_number,

                street_street_address: state.businessAdress?.fullAddress?.STREET || data[0]?.street_street_address,
                street_address_complex: '',
                street_address_province: state.businessAdress?.fullAddress?.PROVINCE,
                street_address_suburb: state.businessAdress?.fullAddress?.SUBURB || data[0]?.street_address_suburb,
                street_address_city: state.businessAdress?.fullAddress?.CITY || data[0]?.street_address_city,
                street_address_postal_code: state.businessAdress?.postalCode || data[0]?.street_address_postal_code,
            };

            const session = JSON.parse(sessionStorage.getItem('session'));

            const config = {
                headers: {
                    'token': session?.token,
                    mode: 'no-cors'
                }
            };

            const updateClient = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/client/update`, clientData, config)

            if (updateClient?.data?.message === "Success") {

                // const clientContact = {
                //     uid: inputValues?.userID || data[0]?.uid,
                //     name: inputValues?.contactPerson,
                //     role: state.role?.uid,
                //     contact_number: inputValues?.contactNumber,
                //     email_address: inputValues?.email,
                // }

                toast(`Client updated successfully`, {
                    icon: '👋',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                setState({ ...state, isLoading: false })

                clearValues()

                // const assignUser = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/contact/createcontact`, clientContact, config)

                // if (assignUser?.data?.message === "Clientcontact Created Successfully") {

                //     const clientComposites = {
                //         fk_composite_contact_uid: inputValues?.userID,
                //         fk_composite_client_uid: inputValues?.userID,
                //     }

                //     const assignComposites = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/clientcontact`, clientComposites, config)

                //     if (assignComposites?.data?.message === 'Clientcontact Created Successfully') {

                //         toast(`Client updated successfully`, {
                //             icon: '👋',
                //             style: {
                //                 borderRadius: '10px',
                //                 background: '#333',
                //                 color: '#fff',
                //             },
                //             duration: 3000,
                //         });

                //         //clear values and reset states
                //         clearValues()

                //         setState({
                //             ...state,
                //             role: '',
                //             division: '',
                //             userType: '',
                //             assignedStores: '',
                //             status: 1,
                //             activeStatus: 1,
                //             errorID: '',
                //             errorUsername: '',
                //             bAddress: '',
                //             dAddress: '',
                //             isLoading: false,
                //             operationCompleted: 'completed'
                //         })
                //     }
                //     else {
                //         setState({ ...state, isLoading: false })

                //         toast('Failed to update client3, please try again', {
                //             icon: '❌',
                //             style: {
                //                 borderRadius: '10px',
                //                 background: '#333',
                //                 color: '#fff',
                //             },
                //             duration: 3000,
                //         });
                //     }
                // }
                // else {
                //     setState({ ...state, isLoading: false })

                //     toast('Failed to update client2, please try again', {
                //         icon: '❌',
                //         style: {
                //             borderRadius: '10px',
                //             background: '#333',
                //             color: '#fff',
                //         },
                //         duration: 3000,
                //     });
                // }

            }
            else {
                setState({ ...state, isLoading: false })

                toast('Failed to update client, please try again', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });
            }
        }
        catch (error) {
            setState({ ...state, isLoading: false })

            toast('Failed to update client, please try again', {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }
    }

    return (
        <Layout>
            {
                data?.map((client) =>
                    <div className="flex flex-col justify-start w-full gap-4 p-2 overflow-y-scroll border h-[95vh] pb-96">
                        <div className='flex items-center justify-between w-full'>
                            <div className='flex flex-col justify-start w-1/2 gap-0'>
                                <p className="text-base font-semibold text-purple lg:text-3xl">Edit Clients</p>
                                <p className="flex items-center justify-start gap-1 text-sm font-bold text-gray-500 lg:text-lg">#{`${client.company_name}`} <span className="hidden lg:flex">{client.registered_name} ${client.company_contact_email_address && client.company_contact_email_address}</span></p>
                            </div>
                            <div className="flex flex-wrap items-center justify-end w-1/2 gap-2 lg:justify-end">
                                <button className="border border-red p-1 md:p-2 w-[50%] md:w-[20%] text-red hover:bg-red hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer" onClick={goBack}>Close</button>
                            </div>
                        </div>
                        <form onSubmit={editUser} className="w-full flex flex-col justify-between gap-4 items-start pr-1 md:p-0 h-[90%]">
                            <div className="flex flex-col justify-start gap-6">
                                <div className="flex flex-wrap items-start justify-start gap-2">
                                    <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                        <label className="text-base font-medium text-gray-500">User ID</label>
                                        <input
                                            type='number'
                                            name="userID"
                                            disabled
                                            value={client.uid}
                                            className={`bg-white w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow ${state.errorID && 'border border-red'}`}
                                        />
                                    </div>
                                    <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                        <label className="text-base font-medium text-gray-500">Company Name</label>
                                        <input
                                            type='text'
                                            name="companyName"
                                            placeholder="Otima Plastics"
                                            onChange={handleChange}
                                            defaultValue={client.com}
                                            value={data[0]?.company_name || inputValues.companyName}
                                            className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                                        />
                                    </div>
                                    <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                        <label className="text-base font-medium text-gray-500">Registered Company Name</label>
                                        <input
                                            type='text'
                                            name="regCompanyName"
                                            placeholder="Otima"
                                            onChange={handleChange}
                                            value={data[0]?.registered_name || inputValues.regCompanyName}
                                            className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                                        />
                                    </div>
                                    <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                        <label className="text-base font-medium text-gray-500">REG Number</label>
                                        <input
                                            type='number'
                                            name="regNumber"
                                            placeholder="9898770GH9"
                                            onChange={handleChange}
                                            value={data[0]?.registration_number || inputValues.regNumber}
                                            className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                                        />
                                    </div>
                                    <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                        <label className="text-base font-medium text-gray-500">VAT Number</label>
                                        <input
                                            type='text'
                                            name="vatNumber"
                                            placeholder="60691300078"
                                            onChange={handleChange}
                                            value={data[0]?.vat_number || inputValues.vatNumber}
                                            className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                                        />
                                    </div>
                                    <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                        <label className="text-base font-medium text-gray-500">Contact Person</label>
                                        <input
                                            type='text'
                                            name="contactPerson"
                                            placeholder="John Doe"
                                            onChange={handleChange}
                                            value={inputValues.contactPerson || ''}
                                            className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                                        />
                                    </div>
                                    <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                        <label className="text-base font-medium text-gray-500">Contact Number</label>
                                        <input
                                            type='text'
                                            name="contactNumber"
                                            placeholder="011 236 9078"
                                            onChange={handleChange}
                                            value={data[0]?.company_contact_number || inputValues.contactNumber}
                                            className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                                        />
                                    </div>
                                    <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                        <label className="text-base font-medium text-gray-500">Email</label>
                                        <input
                                            type='text'
                                            name="email"
                                            placeholder="jd@example.com"
                                            onChange={handleChange}
                                            value={data[0]?.company_contact_email_address || inputValues.email}
                                            className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                                        />
                                    </div>
                                    <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                        <label className="text-base font-medium text-gray-500">Delivery Target Time <span className="text-sm">(mins)</span></label>
                                        <input
                                            type='number'
                                            name="dTime"
                                            placeholder="10"
                                            onChange={handleChange}
                                            value={data[0]?.delivery_target_time || inputValues.dTime}
                                            className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                                        />
                                    </div>
                                    <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                        <label className="text-base font-medium text-gray-500">Role</label>
                                        <Roles handleRoles={handleRoles} operationStatus={state.operationCompleted} />
                                    </div>
                                    <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                        <label className="text-base font-medium text-gray-500">Store Type</label>
                                        <StoreTypes handleAssignedStores={handleAssignedStores} operationStatus={state.operationCompleted} />
                                    </div>
                                    <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                        <label className="text-base font-medium text-gray-500">Preferred Delivery Day</label>
                                        <DeliveryDays handleDeliveryDates={handleDeliveryDates} />
                                    </div>
                                </div>
                                <div className="flex flex-wrap items-start justify-start w-full gap-2">
                                    <div className="w-full border-b-2 border-purple"></div>
                                    <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                        <label className="text-base font-medium text-gray-500">Business Address</label>
                                        <AddressAutocomplete handleAddress={handleAddress} />
                                    </div>
                                    <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                        <label className="text-base font-medium text-gray-500">Shipping Address</label>
                                        <AddressAutocomplete handleAddress={handleAddress} />
                                    </div>
                                    <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                        <label className="text-base font-medium text-gray-500">Unit Number</label>
                                        <input
                                            type='number'
                                            name="unitNumber"
                                            placeholder="65"
                                            onChange={handleChange}
                                            value={inputValues.unitNumber || ''}
                                            className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                                        />
                                    </div>
                                    <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                        <label className="text-base font-medium text-gray-500">Complex Name</label>
                                        <input
                                            type='text'
                                            name="complexName"
                                            placeholder="Monte Blanc Villas"
                                            onChange={handleChange}
                                            value={inputValues.complexName || ''}
                                            className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-wrap items-start justify-start w-full gap-2">
                                    <div className="w-full border-b-2 border-purple"></div>
                                    <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                        <label className="text-base font-medium text-gray-500">Credit Warning</label>
                                        <input
                                            type='number'
                                            name="creditWarning"
                                            placeholder="R200 000"
                                            onChange={handleChange}
                                            value={data[0]?.credit_limit_warning || inputValues.creditWarning}
                                            className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                                        />
                                    </div>
                                    <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                        <label className="text-base font-medium text-gray-500">Approved Credit Limit</label>
                                        <input
                                            type='number'
                                            name="creditLimit"
                                            placeholder="R250 000"
                                            onChange={handleChange}
                                            value={data[0]?.approved_credit_limit || inputValues.creditLimit}
                                            className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                                        />
                                    </div>
                                    <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                        <label className="text-base font-medium text-gray-500">Price List Tier</label>
                                        <PriceList handleAssignedTiers={handleAssignedTiers} />
                                    </div>
                                    <div className='flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300'>
                                        <label className='text-base font-medium text-gray-500'>{state.status === 0 ? 'In-Active' : 'Active'}</label>
                                        <label className='relative inline-block w-20 rounded-full h-9'>
                                            <input type='checkbox' className='w-0 h-0 opacity-0 peer' defaultChecked='active' onClick={toggleStatus} />
                                            <span className={`absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-${state.status === 0 ? 'red' : 'green'} rounded-full duration-300 before:content-[''] before:absolute before:w-7 before:h-7 before:bottom-1 before:left-1 before:rounded-full before:bg-white before:duration-300 peer-checked:before:translate-x-11 peer-checked:bg-green`} ></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="flex flex-wrap items-start justify-start w-full -mt-2">
                                    <button type='submit' className='bg-green text-white py-2 px-10 rounded cursor-pointer text-[1.2rem]'>
                                        {
                                            state.isLoading ?
                                                <p className='flex items-center justify-center gap-2'>
                                                    Saving
                                                    <img src={loadingIcon} alt='Loading' loading='lazy' className='w-6 animate-spin' />
                                                </p>
                                                :
                                                <p className='flex items-center justify-center gap-2'>
                                                    Save Changes
                                                </p>
                                        }
                                    </button>
                                </div>
                            </div>
                        </form >
                    </div >
                )
            }
        </Layout>
    )
}
