import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useLocation } from "react-router-dom"

// Images
import arrow from '../../assets/icons/chevup.svg';

const Table = ({ headers, data, onInvoiceClick, sizing }) => {
    const location = useLocation();
    const path = location.pathname

    const ITEMS_PER_PAGE = 9;
    const paginationCount = 3;
    const totalItems = data.length;
    const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);

    const [currentPage, setCurrentPage] = useState(1);

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    const headerWidth = `${100 / headers.length}%`;

    const handlePageChange = (page) => setCurrentPage(page)

    //determines what color to assign to the text based on the text content 
    const getCellClass = (cell) => {
        switch (cell) {
            case 'Cancelled':
            case 'R 0.00':
            case 'In-Active':
            case 'Closed':
            case 'missing':
                return 'text-red font-medium text-center';
            case 'Completed':
            case 'Delivered':
            case 'Delivery':
            case 'Out For Delivery':
            case 'Active':
            case 'Open':
                return 'text-green font-medium text-center';
            case 'Picking':
            case 'Stock':
                return 'text-[#1ec3ff] font-medium text-center';
            case 'Comm AP':
            case "Commercial":
                return 'text-purple font-medium text-center';
            case 'Fin AP':
            case 'Loading':
                return 'text-yellow font-medium text-center';
            case 'Stock AP':
            case 'In progress':
            case 'Finance':
                return 'text-orange font-medium text-center';
            default:
                return '';
        }
    };

    //creates and renders pagination
    const pagination = () => {
        const pageNumbers = [];
        let renderEllipsisLeft = false;
        let renderEllipsisRight = false;

        if (totalPages <= paginationCount) {
            pageNumbers.push(...Array.from({ length: totalPages }, (_, index) => index + 1));
        }
        else {
            if (currentPage <= Math.ceil(paginationCount / 2)) {
                pageNumbers.push(...Array.from({ length: paginationCount }, (_, index) => index + 1));
                renderEllipsisRight = true;
            } else if (currentPage >= totalPages - Math.floor(paginationCount / 2)) {
                pageNumbers.push(...Array.from({ length: paginationCount }, (_, index) => totalPages - paginationCount + 1 + index));
                renderEllipsisLeft = true;
            } else {
                const offset = Math.floor(paginationCount / 2);
                pageNumbers.push(...Array.from({ length: paginationCount }, (_, index) => currentPage - offset + index));
                renderEllipsisLeft = true;
                renderEllipsisRight = true;
            }
        }

        return (
            <>
                {renderEllipsisLeft && <span className="px-2 py-2 mx-1 text-sm font-medium text-gray-500">...</span>}
                {pageNumbers.map((pageNumber) => <button key={pageNumber} className={`h-6 sm:h-8 w-6 sm:w-8 mx-1 text-xs sm:text-sm font-medium ${pageNumber === currentPage ? 'bg-purple text-white border-purple border-[1px]' : 'md:border border-black-light lg:ease-in-out duration-500 text-gray-500'} rounded-lg outline-none`} onClick={() => handlePageChange(pageNumber)}>{pageNumber}</button>)}
                {renderEllipsisRight && <span className="px-2 py-2 mx-1 text-sm font-medium text-gray-500">...</span>}
            </>
        );
    };

    //controls what columns can be clicked on the table and the actions to do when clicked 
    const clickableItem = (item, cellIndex) => {
        if (cellIndex === 0) {
            onInvoiceClick(item)
        }
        else if (cellIndex === headers.length - 1 || cellIndex === headers.length - 2 || cellIndex === headers.length - 3) {
            // allow for desired action to be triggered
        }
        else {
            toast(`Click the ${path === '/' || path === '/approvals' ? 'Order Number' : path === '/clients' ? 'Client ID' : path === '/users' ? 'User ID' : path === '/iot' ? 'store name' : 'Vehicle ID'} to ${path === '/' || path === '/approvals' ? 'view this invoice' : path === '/clients' ? 'view this client' : path === '/users' ? 'view this user' : path === '/iot' ? 'view the metrics' : 'view this vehicle'}`,
                {
                    icon: '💡',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                }
            );
        }
    }

    //main table
    return (
        <div className="flex flex-col justify-start w-full h-full gap-3 overflow-hidden">
            <div className="flex items-center justify-between text-center bg-white rounded p-2 md:p-4 divide-x divide-grey-mid shadow w-full h-[5%]">
                {headers.map((header, index) => <div key={index} className={`text-center ${sizing?.includes(index) ? 'hidden md:block' : ''} w-full text-center text-xs font-medium text-gray-500 uppercase tracking-wider`} style={{ width: headerWidth }}>{header}</div>)}
            </div>
            <div className="flex flex-col gap-2 h-[88%] w-full ">
                {data.slice(startIndex, endIndex).map((item, rowIndex) => {
                    const { uid, ...itemData } = item;

                    return (
                        <div key={rowIndex} className={`flex items-center justify-between w-full p-2 bg-white rounded shadow cursor-pointer hover:bg-gray-50`}>
                            {Object.values(itemData).map((cell, cellIndex) => <div key={cellIndex} onClick={() => clickableItem(item, cellIndex)} className={`whitespace-nowrap text-xs sm:text-sm text-center text-gray-500 ${getCellClass(cell)} ${sizing?.includes(cellIndex) ? 'hidden md:block' : ''} w-full lg:ease-in-out duration-500 ${cellIndex === 0 && 'text-purple'} ${(path === '/iot' && cellIndex > 2 && cell > 180) && 'text-red font-semibold'} ${(path === '/iot' && cellIndex > 2 && cell < 180) && 'text-green font-semibold'}`} style={{ width: headerWidth }}>{cell}</div>)}
                        </div>
                    );
                })}
            </div>
            <div className="flex items-center justify-center gap-1 overflow-x-hidden h-[7%]">
                <figure className={`w-8 cursor-pointer ${currentPage === 1 ? 'opacity-50' : ''} rotate-90`} onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    <img src={arrow} alt="" loading="lazy" className="rotate-180" />
                </figure>
                {pagination()}
                <figure className={`w-8 cursor-pointer ${currentPage === totalPages ? 'opacity-50' : ''} `} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                    <img src={arrow} alt="" loading="lazy" className="rotate-90" />
                </figure>
            </div>
        </div>
    );
};

export default Table;
