import { useState } from 'react';
import plusLogo from '../assets/logos/plusLogo.svg'
import { InputHandler } from '../hooks/useInputHandler';

export const Ratings = () => {
    const [state, setState] = useState()

    const { inputValues, handleChange } = InputHandler();

    return (
        <div className='flex items-center justify-center w-full h-screen bg-grey'>
            <div className='flex flex-col justify-start w-11/12 gap-4 p-2 overflow-y-scroll bg-white border rounded shadow-lg h-[90%] md:w-8/12'>
                <figure>
                    <img src={plusLogo} alt='Plus Stores' loading='lazy' className='w-28' />
                </figure>
                <hr className='border border-black' />
                <div className='flex flex-col justify-start gap-1'>
                    <p className='text-sm leading-none'>Thank you for rating your experience with us.</p>
                    <p className='text-sm leading-none'>Please provide us with more details on your experience.</p>
                </div>
                <hr className='border border-black' />
                <form className='flex flex-col justify-start gap-4'>
                    <div className='flex flex-col justify-start gap-0'>
                        <label className='text-base font-medium text-gray-500'>Your Name</label>
                        <input
                            id='name'
                            type=' text'
                            name='name'
                            value={inputValues.name || ''}
                            onChange={handleChange}
                            className='p-[7px] border rounded text-base text-gray-500'
                        />
                    </div>
                    <div className='flex flex-col justify-start gap-0'>
                        <div className='flex flex-col justify-start gap-2'>
                            <span className='w-full p-[9px] text-base font-medium text-gray-500 border border-gray-300 rounded cursor-pointer'>Select A Store</span>
                            <ul></ul>
                        </div>
                    </div>
                    <div className='flex flex-col justify-start gap-0'>
                        <label className='text-base font-medium text-gray-500'>Your Contact Number</label>
                        <input
                            id='contact'
                            type=' number'
                            name='contact'
                            value={inputValues.contact || ''}
                            onChange={handleChange}
                            className='p-[7px] border rounded text-base text-gray-500'
                        />
                    </div>
                    <div className='flex flex-col justify-start gap-0'>
                        <label className='text-base font-medium text-gray-500'>Reaction</label>
                        <input
                            id='contact'
                            type=' number'
                            className='p-[7px] border rounded text-base text-gray-500'
                        />
                    </div>
                    <div className='flex flex-col justify-start gap-0'>
                        <label className='text-base font-medium text-gray-500'>Please rate our service</label>
                        <input
                            type='text'
                            className='p-[7px] border rounded text-base text-gray-500'
                        />
                    </div>
                    <div className='flex flex-col justify-start gap-0'>
                        <label className='text-base font-medium text-gray-500'>How likely are you to recommend us ?</label>
                        <input
                            type='text'
                            className='p-[7px] border rounded text-base text-gray-500'
                        />
                    </div>
                    <div className='flex flex-col justify-start gap-0'>
                        <label className='text-base font-medium text-gray-500'>Additional Comments</label>
                        <textarea
                            id='contact'
                            type=' number'
                            name='contact'
                            value={inputValues.contact || ''}
                            onChange={handleChange}
                            className='p-[7px] border rounded text-base text-gray-500 h-28'
                        />
                    </div>
                    <button className='p-2 text-white rounded outline-none cursor-pointer bg-green'>Submit Ratings</button>
                </form>
            </div>
        </div>
    )
}
