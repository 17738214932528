import axios from "axios";
import { isEmpty } from "lodash";
import { toast } from "react-hot-toast";
import jsPDF from "jspdf";

export const pdfResolver = async (invoice) => {
    let encodedPDF = null;

    if (invoice?.uid) {
        const user = JSON.parse(sessionStorage.getItem('session'));

        const config = {
            headers: {
                'token': user?.token,
                mode: 'no-cors'
            }
        };

        try {
            const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/v1/ordermodel/itemsoutofstock/${invoice?.uid}`, config);
            const orderInfo = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/v1/order/${invoice?.uid}`, config);
            const orderCount = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/v1/ordermodel/invoiceinfo/${invoice?.uid}`, config);

            console.log(response?.data?.length)

            if (response?.data && !isEmpty(response?.data)) {

                if (orderInfo?.data && !isEmpty(orderInfo?.data)) {

                    const pdf = new jsPDF();

                    let count = 1
                    let count2 = 0
                    let count3 = orderCount?.data.map(item => item.count)

                    response?.data.map(item => (count2++))
                    orderCount?.data.map(item => item.count)
                    let count4 = count3 - count2
                    let count5 = count4/count3 * 100 
                    //console.log(count5)
                    //count5 is the amount in percentage of the order that was fulfilled
                    //add this logic to the table-helper when sending the message. Add to body to be used in backend route
                    const concatenatedText = `Thank you for your order!\n\nOrder date: ${orderInfo?.data.map(item => item.creation_date.slice(0, 19)?.replace('T', ' '))}\n\nApologies, the following items are unfortunately out of stock:\n\n` + response?.data.map(item => (count++) + ". " + item.name).join("\n");
                    pdf.text(concatenatedText, 10, 10);

                    const pdfBase64 = pdf.output('dataurl');
                    const [, base64Data] = pdfBase64.split(",");
                    encodedPDF = base64Data;

                    return encodedPDF;

                }
            }
            else {
                toast(`There are no out of stock items for order: ${invoice?.uid}`, {
                    icon: '✅',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    return encodedPDF;
}