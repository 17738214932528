import { useEffect, useState } from "react";
import { toast } from 'react-hot-toast';
import axios from 'axios';

//hooks
import { useSessionPolling } from "../../../hooks/useSessionPolling"
import { InputHandler } from "../../../hooks/useInputHandler";

//components
import { isEmpty } from "lodash";
import { EditModal } from "./settings-modal/general-edit-modal";
import { AddModal } from "./settings-modal/general-add-modal";

//request configuration
import { config } from '../../../utils/app-settings';

//images
import loadingIcon from "../../../assets/icons/wloader.svg";
import { SOHSettings } from "../../../helpers/order/getSOHSetting";

export const General = () => {
    const [state, setState] = useState({
        isLoading: '',
        isVisible: null,
        isVisible2: null,
        rateOfSale: [],
        categories: [],
        property: [],
        status: 0
    })

    const { inputValues, handleChange } = InputHandler();

    const userID = useSessionPolling("session");

    const extractTokenFromSession = () => {
        const session = JSON.parse(sessionStorage.getItem('session'));
        const token = session?.token || null;
        return token;
    };

    const getRateOfSale = axios.create({
        baseURL: `${process.env.REACT_APP_ENDPOINT}/api/v1/settings/dynamicrateofsale/${userID?.uid}`,
        mode: "no-cors",
        headers: { token: extractTokenFromSession() },
    });

    const getCategory = axios.create({
        baseURL: `${process.env.REACT_APP_ENDPOINT}/api/v1/client/getcategory/`,
        mode: "no-cors",
        headers: { token: extractTokenFromSession() },
    });

    useEffect(() => {
        // Fetch rate of sale data
        getRateOfSale.get("/")
            .then((res) => {
                if (!isEmpty(res.data)) {
                    setState((prevState) => ({
                        ...prevState,
                        rateOfSale: res.data,
                    }));
                }
            })
            .catch((error) => {
                // Handle error if needed
                console.error("Error fetching rate of sale data:", error);
            });

        // Fetch categories data
        getCategory.get("/")
            .then((res2) => {
                if (!isEmpty(res2.data)) {
                    setState((prevState) => ({
                        ...prevState,
                        categories: res2.data,
                    }));
                }
            })
            .catch((error) => {
                // Handle error if needed
                console.error("Error fetching categories data:", error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userID?.uid]);

    // useEffect(() => {
    //     const fetchSettings = async () => {
    //         // Fetch SOH settings
    //         const sohSetting = SOHSettings();

    //         if (sohSetting && sohSetting[0]?.enabled !== undefined) {
    //             setState(prevState => ({
    //                 ...prevState,
    //                 status: sohSetting[0].enabled ? 1 : 0
    //             }));
    //         }
    //     };

    //     fetchSettings();
    // }, []); 

    const addCategory = async (inputValues) => {
        if (!isEmpty(inputValues?.category)) {
            try {

                const payLoad = {
                    category_name: inputValues?.category,
                    colour_code: '#000000'

                }

                const createCategory = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/client/createcategory/`, payLoad, config)

                if (createCategory?.data?.message === 'Success') {

                    toast(`Wand profile added successfully`, {
                        icon: '👋',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });

                    closeModal2()

                    setTimeout(() => {
                        window.location.reload()
                    }, 3100);

                } else {

                    toast('Failed to add wand profile, please try again', {
                        icon: '❌',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });

                    closeModal2()

                    setTimeout(() => {
                        window.location.reload()
                    }, 3100);
                }
            }
            catch (error) {

                toast('Error adding wand profile, try again later', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                closeModal2()

                setTimeout(() => {
                    window.location.reload()
                }, 3100);
            }
        } else {
            toast('Error please fill in all the fields', {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }


    };

    const updateCategory = async (product, inputValues, colour) => {

        console.log(colour)

        if (!isEmpty(inputValues?.category) || !isEmpty(colour)) {
            try {


                const payLoad = {
                    category_name: inputValues?.category || product?.category_name,
                    colour_code: colour || product?.colour_code
                }

                const updateCategory = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/client/category/${product?.uid}`, payLoad, config)

                if (updateCategory?.data?.message === 'Success') {

                    toast(`Store category updated successfully`, {
                        icon: '👋',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });

                    closeModal()

                    setTimeout(() => {
                        window.location.reload()
                    }, 3100);

                } else {

                    toast('Failed to update store category, please try again', {
                        icon: '❌',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });

                    closeModal()

                    setTimeout(() => {
                        window.location.reload()
                    }, 3100);
                }
            }
            catch (error) {

                toast('Error updating store category, try again later', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                closeModal()

                setTimeout(() => {
                    window.location.reload()
                }, 3100);
            }
        } else {
            toast('Error please fill in all the fields to make changes', {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }
    };

    const applyRateOfSale = async (e) => {
        e.preventDefault()

        setState({ ...state, isLoading: true });

        try {

            const updateROS = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/settings/updatedynamicrateofsale/${inputValues?.minVal || state.rateOfSale[0]?.ROS_Min}/${inputValues?.avgVal || state.rateOfSale[0]?.ROS_Average}/${inputValues?.maxVal || state.rateOfSale[0]?.ROS_Max}/${userID?.uid}`, {}, config)

            if (updateROS?.data?.message === 'Success') {

                setState({ ...state, isLoading: false });

                toast(`Rate of sales updated successfully`, {
                    icon: '👋',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                setTimeout(() => {
                    window.location.reload()
                }, 3100);

            } else {

                setState({ ...state, isLoading: false });

                toast('Failed to updated rate of sale, please try again', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                setTimeout(() => {
                    window.location.reload()
                }, 3100);
            }
        }
        catch (error) {

            setState({ ...state, isLoading: false });

            toast('Error updating rate of sale, try again later', {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });

            setTimeout(() => {
                window.location.reload()
            }, 3100);
        }
    }

    const deleteCategory = async (Profile_UID) => {

        try {

            const profileDelete = await axios.delete(`${process.env.REACT_APP_ENDPOINT}/api/v1/client/deletecategory/${Profile_UID}`, config)

            if (profileDelete?.data?.message === 'Success') {

                toast(`Profile deleted successfully`, {
                    icon: '👋',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                setTimeout(() => {
                    window.location.reload()
                }, 3100);

            } else {

                toast('Failed to delete profile, please try again', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                setTimeout(() => {
                    window.location.reload()
                }, 3100);
            }
        }
        catch (error) {

            toast('Error deleting profile, try again later', {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });

            setTimeout(() => {
                window.location.reload()
            }, 3100);
        }
    }

    const openModal = (setting) => {
        setState((prevState) => ({ ...prevState, isVisible: true, property: setting }));
    };

    const openModal2 = (property) => setState({ ...state, isVisible2: !state.isVisible2, product: property });

    const closeModal = () => setState((prevState) => ({ ...prevState, isVisible: false }));
    const closeModal2 = () => setState((prevState) => ({ ...prevState, isVisible2: false }));

    const sohSetting = SOHSettings();

    if (!sohSetting) {
        return;
    }

    const { enabled } = sohSetting?.[0]

    const status = state.status === 0 ? enabled : state.status

    const assignStatus = () => {}

    const colorToMarker = {
        lavender: '#E3ABFF',
        green: '#0c7',
        aqua: '#50E9F3',
        yellow: '#FEA800',
        violet: '#685CFF',
        red: '#BE0038',
        purple: '#801E9F',
        pink: '#FF3781',
        orange: '#f40',
        lime: '#7DED56',
    };

    return (
        <div className="w-full h-[78%] flex flex-col justify-start gap-4 overflow-y-scroll overflow-x-hidden">
            <div className='text-lg' >
                <p className="text-xl font-medium text-gray-500">Rate of Sales</p>
            </div>
            <hr className="border border-grey-darker w-[94vw]" />
            {
                state.rateOfSale?.map((item) =>
                    <div key={item?.uid} className="flex flex-col justify-start w-full gap-3 md:w-3/12">
                        <div className="flex items-center justify-between">
                            <label className="text-base" >Minimum Value</label>
                            <input className='w-16 text-base text-center rounded-md border-1 border-purple hover:border-2 focus:border-2' type='number' name="minVal" defaultValue={item?.ROS_Min} onChange={handleChange} />
                        </div>
                        <div className="flex items-center justify-between">
                            <label className="text-base" >Average Value</label>
                            <input className='w-16 text-base text-center rounded-md border-1 border-purple hover:border-2 focus:border-2' type='number' name="avgVal" defaultValue={item?.ROS_Average} onChange={handleChange} />
                        </div>
                        <div className="flex items-center justify-between">
                            <label className="text-base" >Maximum Value</label>
                            <input className='w-16 text-base text-center rounded-md border-1 border-purple hover:border-2 focus:border-2' type='number' name="maxVal" defaultValue={item?.ROS_Max} onChange={handleChange} />
                        </div>
                    </div>
                )
            }
            <button onClick={applyRateOfSale} className='bg-green text-white w-32 px-2 rounded cursor-pointer text-[1.2rem]'>
                {
                    state.isLoading ?
                        <p className='flex items-center justify-center gap-2'>
                            Applying
                            <img src={loadingIcon} alt='Loading' loading='lazy' className='w-6 animate-spin' />
                        </p>
                        :
                        <p className='flex items-center justify-center gap-2'>
                            Apply
                        </p>
                }
            </button>

            {(userID?.type?.toLowerCase()?.includes('super admin') || userID?.type?.toLowerCase()?.includes('dc user')) &&
                <div className="flex flex-col justify-start w-full h-full gap-4">
                    <div className='text-lg' >Store Categories</div>
                    <hr className="border border-grey-darker w-[94vw]" />
                    <div className="flex flex-col items-start justify-center w-full gap-4 md:w-3/12">
                        {
                            state.categories?.map((setting, index) =>
                                <div className="flex items-center justify-between w-full " key={index}>
                                    <p className="flex items-center justify-start w-1/4 text-left">{setting?.category_name}</p>
                                    <div className="flex items-center justify-center w-1/4">
                                        <p className="h-6 rounded-[50%] inline-block w-6" style={{ backgroundColor: colorToMarker[setting?.colour_code] }}></p>
                                    </div>
                                    <div className="flex items-center justify-center w-1/4">
                                        <button onClick={() => openModal(setting)} className="px-6 py-1 text-sm duration-300 border rounded cursor-pointer border-purple text-purple hover:bg-purple hover:text-white lg:ease-in-out">Edit</button>
                                    </div>
                                    <div className="flex items-center justify-center w-1/4">
                                        <button onClick={() => { deleteCategory(setting?.uid) }} className="px-6 py-1 text-sm duration-300 border rounded cursor-pointer border-red text-red hover:bg-red hover:text-white lg:ease-in-out">Delete</button>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <button onClick={openModal2} className="w-32 px-2 py-1 text-base text-white duration-300 rounded cursor-pointer bg-green lg:ease-in-out">Add Category</button>
                    {state.isVisible2 && <AddModal closeModal={closeModal2} addCategory={addCategory} />}
                    {state.isVisible && <EditModal category={state.property} closeModal={closeModal} updateCategory={updateCategory} />}
                </div>}

            {(userID?.type?.toLowerCase()?.includes('super admin') || userID?.type?.toLowerCase()?.includes('dc user')) &&
                <div className="w-full h-full flex flex-col justify-start gap-4">
                    <div className='text-lg' >SOH Setting</div>
                    <hr className="border border-grey-darker w-[94vw]" />
                    <div className="w-full md:w-3/12 flex flex-col items-start justify-center gap-4">
                        <div className="flex items-center justify-start gap-2">
                            <span className="text-base text-gray-500">Enable</span>
                            <button className={`${status === 1 ? "bg-green" : "bg-red"} bg-green p-2 w-20 h-10 rounded-full relative flex items-center justify-between outline-none`} onClick={assignStatus}>
                                {status === 1 ? <span className="bg-white p-3 rounded-full h-4 w-4 absolute right-2 transform rotate-45"></span> : <span className="bg-white p-3 rounded-full h-5 w-5 absolute left-2"></span>}
                            </button>
                        </div>
                    </div>
                    <button onClick={openModal2} className="bg-green text-white w-32 py-1 px-2 rounded cursor-pointer text-base lg:ease-in-out duration-300">Add Category</button>
                    {state.isVisible2 && <AddModal closeModal={closeModal2} addCategory={addCategory} />}
                    {state.isVisible && <EditModal category={state.property} closeModal={closeModal} updateCategory={updateCategory} />}
                </div>}
        </div>
    )
}
