import { useHoursHistory } from "./useHoursHistory";
import loaderIcon from '../../../assets/icons/splashloader.svg';
import { UseTimeEvaluator, UseDateEvaluator } from "../../../helpers/time-evaluator";

export const WorkHoursHistory = () => {
    const data = useHoursHistory()

    if (!data || data.length === 0) {
        return (
            <div className='flex items-center justify-center w-full mb-4'>
                <img src={loaderIcon} alt='Loading' className="w-12 animate-spin" />
            </div>
        )
    }

    const tableData = data?.map((property) => {
        const startTime = property?.doorstate?.toLocaleLowerCase()?.includes('open') ? '07:00' : '19:00'

        const openingTimeDiff = property.doortime?.slice(11, 19) ? UseDateEvaluator(startTime, property.doortime?.slice(11, 19)) : 0

        return {
            uid: property.idx,
            shopName: property.shopname,
            doorName: property.doorname,
            doorState: property.doorstate,
            doorTime: property.doortime?.slice(11, 19),
            doorDate: property.doortime?.slice(0, 10),
            clientUID: property.client_uid,
            stdOpenTime: property.std_open_time ? property.std_open_time : '--:--',
            stdCloseTime: property.std_close_time ? property.std_close_time : '--:--',
            timeDiff: UseTimeEvaluator(Number(String(openingTimeDiff)?.replace(/[^0-9]/g, ''))),
            openStatus: openingTimeDiff >= 1 ? 'Late' : openingTimeDiff === 0 ? `On-Time` : 'Early',
        }
    })

    return (
        <div className="z-40 flex flex-col justify-start w-full gap-2 overflow-y-scroll bg-white rounded rounded-b shadow">
            <div className="flex flex-col justify-start w-full gap-2 p-1 rounded bg-white h-[300px] overflow-y-scroll">
                {tableData?.map((item) =>
                    <div className="relative flex items-center justify-between w-full gap-1 p-2 duration-300 rounded lg:ease-in-out lg:rounded lg:cursor-pointer lg:hover:bg-black-skeleton" key={item?.doorDate}>
                        {item?.doorState?.toLocaleLowerCase()?.includes('open') &&
                            <div className="w-[55%] flex self-start" key={`${item?.doorDate}-${item?.doorState}`}>
                                <div className="w-[20%] flex items-center justify-center">
                                    <p className="text-sm font-medium text-gray-500">{item?.doorDate}</p>
                                </div>
                                <div className="w-[20%] flex items-center justify-center">
                                    <p className="text-sm font-medium text-gray-500">{item?.stdOpenTime}</p>
                                </div>
                                <div className="w-[20%] flex items-center justify-center">
                                    <p className="text-sm font-medium text-gray-500">{item?.doorTime}</p>
                                </div>
                                <div className="w-[20%] flex items-center justify-center">
                                    <p className={`text-sm font-medium text-gray-500 ${item?.openStatus?.toLocaleLowerCase()?.includes('late') || item?.openStatus === 0 ? 'text-red' : 'text-green'}`}>{`${item?.timeDiff?.hours}hrs ${item?.timeDiff?.minutes}mins`}</p>
                                </div>
                                <div className={`w-[20%] flex items-center justify-end`}>
                                    <span className={`font-medium text-center w-[90%] text-white ${item?.openStatus?.toLocaleLowerCase()?.includes('late') || item?.openStatus === 0 ? 'bg-red' : 'bg-green'} rounded p-1`} >{item?.openStatus}</span>
                                </div>
                            </div>
                        }
                        {item?.doorState?.toLocaleLowerCase()?.includes('closed') &&
                            <div className="w-[45%] flex self-start absolute right-0 top-8" key={`${item?.doorDate}-${item?.doorState}`}>
                                <div className="w-[25%] flex items-center justify-center">
                                    <p className="text-sm font-medium text-gray-500">{item?.stdCloseTime}</p>
                                </div>
                                <div className="w-[25%] flex items-center justify-center">
                                    <p className="text-sm font-medium text-gray-500">{item?.doorTime}</p>
                                </div>
                                <div className="w-[25%] flex items-center justify-center">
                                    <p className={`text-sm font-medium text-gray-500 ${item?.openStatus?.toLocaleLowerCase()?.includes('late') || item?.openStatus === 0 ? 'text-red' : 'text-green'}`}>{`${item?.timeDiff?.hours}hrs ${item?.timeDiff?.minutes}mins`}</p>
                                </div>
                                <div className={`w-[25%] flex items-center justify-end`}>
                                    <span className={`font-medium text-center w-11/12 text-white ${item?.openStatus?.toLocaleLowerCase()?.includes('late') || item?.openStatus === 0 ? 'bg-red' : 'bg-green'} rounded p-1`} >{item?.openStatus}</span>
                                </div>
                            </div>
                        }
                    </div>
                )}
            </div>
        </div>
    )
}