import axios from 'axios';
import { useState, useEffect } from 'react';

const parseClientData = (client) => {
    const { uid, name, surname, username, email_address, telegram_number, contact_number } = client;

    return { uid, name, surname, username, email_address, telegram_number, contact_number };
};

export const AllReps = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = JSON.parse(sessionStorage.getItem('session'));

                const config = {
                    headers: {
                        'token': user?.token,
                    },
                };

                const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/v1/repclient/getreps`, config);

                if (response.status === 200) {
                    const clientsData = response.data.map(parseClientData);
                    
                    setData(clientsData);
                }
            } catch (error) {}
        };

        fetchData();
    }, []);

    if (data.length === 0) {
        return null;
    }

    return data;
};
