import React, { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import {
    Label,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

//hooks
import { useQuery } from '../../../../hooks/useQuery';

//images	
import alert from '../../../../assets/icons/alert.svg'
import emptyIcon from '../../../../assets/icons/empty.svg'
import filterIcon from '../../../../assets/icons/filter.svg'
import closeIcon from '../../../../assets/icons/times.svg'
import bchev from '../../../../assets/icons/chevup.svg'

//configuration
import { config } from '../../../../utils/app-settings';

const months = [
    { id: 1, month: 'January' },
    { id: 2, month: 'February' },
    { id: 3, month: 'March' },
    { id: 4, month: 'April' },
    { id: 5, month: 'May' },
    { id: 6, month: 'June' },
    { id: 7, month: 'July' },
    { id: 8, month: 'August' },
    { id: 9, month: 'September' },
    { id: 10, month: 'October' },
    { id: 11, month: 'November' },
    { id: 12, month: 'December' }
];

const years = [2022, 2023, 2024, 2025, 2026];

export const TotalVSDCOrders = () => {
    const [state, setState] = useState({
        pageWidth: '',
        searchResults: '',
        month: null,
        year: null,
    })
    const [activeContainer, setActiveContainer] = useState(null);
    const containerRef = useRef([]);

    const primaryDates = {
        start_date: moment().format('YYYY-MM-DD'),
    }

    const toggleYear = () => setState({ ...state, isPickingYear: !state.isPickingYear })
    const toggleMonth = () => setState({ ...state, isPickingMonth: !state.isPickingMonth })

    const resetFilters = () => {
        setState({
            ...state,
            searchResults: '',
            month: null,
            year: null,
        })
    }

    const findData = async () => {
        try {
            const getFilterResults = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/v1/analytics/getalltotalvsdc/${state.year}-${state.month?.id}-01`, config)

            if (getFilterResults?.data && !isEmpty(getFilterResults?.data)) {
                setState({
                    ...state,
                    searchResults: getFilterResults?.data,
                })

                toast(`Filtering completed successfully`, {
                    icon: '👋',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                closeFilter()
            }
            else if (isEmpty(getFilterResults?.data)) {
                resetFilters()

                toast('There is no data between the selected timeframe', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                closeFilter()
            }
            else {
                resetFilters()

                toast('Failed to fetch the data, please refresh', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                closeFilter()
            }
        }
        catch (error) {
            setState({
                ...state,
                searchResults: '',
                month: null,
                year: null,
            })

            toast('Failed to fetch the data, please refresh', {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }
    }

    const handleResize = () => setState({ ...state, pageWidth: window.innerWidth });

    useEffect(() => {
        // Add event listener to listen for window resize events
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/analytics/getalltotalvsdc/${primaryDates?.start_date}`, "get");

    if (isLoading) {
        return (
            <div className="bg-white p-2 h-[400px] w-full rounded-lg shadow border-2 flex items-center justify-center text-sm text-gray-500 font-medium relative">
                <div className='w-full h-full flex flex-col justify-center items-center gap-2'>
                    <span className='p-3 animate-pulse animate-ping w-3/4 rounded bg-black-skeleton'></span>
                    <span className='p-3 animate-pulse animate-ping w-[15px] rounded bg-black-skeleton absolute top-8 right-7 hidden md:flex'></span>
                    <div className='w-full h-[260px] flex items-center justify-between gap-1 pr-5'>
                        <div className='w-[3%] h-full bg-black-skeleton animate-ping animate-pulse rounded hidden md:flex'></div>
                        <div className='w-full md:w-[95%] h-full bg-black-skeleton animate-ping animate-pulse rounded'></div>
                    </div>
                    <span className='p-3 animate-pulse animate-ping w-1/4 rounded bg-black-skeleton'></span>
                </div>
            </div>
        )
    }

    if (error) {
        window.location.reload();
        return (
            <div className="bg-white p-2 h-[400px] w-full rounded-lg shadow border-2 flex items-center justify-center text-sm text-gray-500 font-medium relative">
                <div className='w-full h-full flex flex-col justify-center items-center gap-2'>
                    <span className='p-3 animate-pulse animate-ping w-3/4 rounded bg-black-skeleton'></span>
                    <span className='p-3 animate-pulse animate-ping w-[15px] rounded bg-black-skeleton absolute top-8 right-7 hidden md:flex'></span>
                    <div className='w-full h-[260px] flex items-center justify-between gap-1 pr-5'>
                        <div className='w-[3%] h-full bg-black-skeleton animate-ping animate-pulse rounded hidden md:flex'></div>
                        <div className='w-full md:w-[95%] h-full bg-black-skeleton rounded relative flex flex-col justify-center items-center'>
                            <div className='bg-white p-2 rounded flex items-center justify-start gap-2'>
                                <img src={alert} loading='lazy' alt='Filter' className='w-10' />
                                <p>We could not load this chart's data</p>
                            </div>
                        </div>
                    </div>
                    <span className='p-3 animate-pulse animate-ping w-1/4 rounded bg-black-skeleton'></span>
                </div>
            </div>
        )
    }

    if (isEmpty(data)) {
        return (
            <div className="bg-white p-2 h-[400px] w-full rounded-lg shadow border-2 flex items-center justify-center text-sm text-gray-500 font-medium relative">
                <div className='w-full h-full flex flex-col justify-center items-center gap-2'>
                    <span className='p-3 animate-pulse animate-ping w-3/4 rounded bg-black-skeleton'></span>
                    <span className='p-3 animate-pulse animate-ping w-[15px] rounded bg-black-skeleton absolute top-8 right-7 hidden md:flex'></span>
                    <div className='w-full h-[260px] flex items-center justify-between gap-1 pr-5'>
                        <div className='w-[3%] h-full bg-black-skeleton animate-ping animate-pulse rounded hidden md:flex'></div>
                        <div className='w-full md:w-[95%] h-full bg-black-skeleton rounded relative flex flex-col justify-center items-center'>
                            <div className='bg-green text-white p-2 rounded flex items-center justify-start gap-2'>
                                <img src={emptyIcon} loading='lazy' alt='Filter' className='w-10' />
                                <p>There is no data for this chart</p>
                            </div>
                        </div>
                    </div>
                    <span className='p-3 animate-pulse animate-ping w-1/4 rounded bg-black-skeleton'></span>
                </div>
            </div>
        )
    }

    //preps data to be mapped into the chart
    const groupedData = state.searchResults ? state.searchResults : data

    const chartData = groupedData?.map((property) => {
        const shortenedName = property.company_name.replace(/store|outlet|plus/gi, '').trim();

        return {
            stage: shortenedName,
            'CPD Cost': property?.total_cpd,
            'DC Cost': property?.cost_dc,
            'turnover': property?.turnover,
            'costOfSales': property?.cost_of_sales,
            'purchases': property?.total_cpd - property?.cost_of_sales
        };
    });

    const toggleContainer = (index) => activeContainer === index ? setActiveContainer(null) : setActiveContainer(index);
    const toggleFilter = (index) => toggleContainer(index)

    const closeFilter = () => setActiveContainer(null);

    //const formatYAxisValue = (value) => value && value >= 1000 && value < 999999 ? `R ${(value / 1000)?.toFixed(0)}k` : (value >= 999999 && value < 10000000000 ? `R ${(value / 1000000)?.toFixed(1)}m` : value?.toString())

    const formatYAxisValue = (value) => {
        if (typeof value !== 'number') {
            return value;
        }

        const sign = value < 0 ?  '-' : '';
        const absValue = Math.abs(value);

        if (absValue >= 1000000 && absValue < 1000000000) {
            return `R${sign}${(absValue / 1000000)?.toFixed(1)}m`;
        } else if (absValue >= 1000000000 && absValue < 10000000000) {
            return `R${sign}${(absValue / 1000000000)?.toFixed(1)}b`;
        } else if (absValue >= 1000 && absValue < 999999) {
            return `R${sign}${(absValue / 1000)?.toFixed(0)}k`;
        }

        return value.toString();
    };

    const ItalizeLabels = (props) => {
        const { x, y, payload } = props;
        return (
            <g transform={`translate(${x},${y})`} >
                <text x={0} y={0} dy={16} textAnchor="end" transform="rotate(-15)" className='text-xs'>
                    {payload?.value}
                </text>
            </g>
        );
    };

    const barValue = ({ x, y, width, value }) => {
        return <text x={x + width / 2} y={y} fill="#353535" textAnchor="middle" dy={-6} className='text-xs text-gray-500'>{`${formatYAxisValue(value)}`}</text>;
    };

    return (
        <div className="bg-white p-2 h-[500px] w-full rounded-lg shadow border-2 flex items-center justify-center text-sm text-gray-500 font-medium relative">
            <div className='w-full h-full flex flex-col justify-center items-center gap-2'>
                <h2 className='text-center text-base md:text-lg'>Total Orders Value VS Orders to DC Value month till date <span className='text-base font-normal'>(R)</span></h2>
                <ResponsiveContainer width="98%" height="80%">
                    <BarChart
                        width={500}
                        height={250}
                        data={chartData}
                        margin={{
                            top: 30,
                            right: 10,
                            left: 10,
                            bottom: 30,
                        }}
                        barSize={state.pageWidth > 500 ? 30 : 10}
                        barGap="1%">
                        <XAxis dataKey="stage" scale="point" padding={{ left: state.pageWidth >= 500 ? 80 : 60, right: state.pageWidth >= 500 ? 80 : 60, }} tick={<ItalizeLabels />} allowDuplicatedCategory={true} interval={0} />
                        <YAxis tick={{ fontSize: 12 }} tickFormatter={(value) => formatYAxisValue(value)}>
                            <Label value="Amount (R)" angle={-90} position="insideLeft" style={{ textAnchor: 'middle' }} dx={-8} />
                        </YAxis>
                        <Tooltip
                            content={({ payload, label, active }) => {
                                console.log(payload)
                                if (active) {
                                    const cpdCost = payload[2]?.value || 0;
                                    const dcCost = payload[4]?.value || 0;
                                    const cpdBarColor = payload[2]?.fill || 'var(--black)';
                                    const dcBarColor = payload[4]?.fill || 'var(--black)';
                                    const turnover = payload[0]?.value || 0;
                                    const costOfSales = payload[1]?.value || 0;
                                    const turnoverBarColor = payload[0]?.fill || 'var(--black)';
                                    const costOfSalesBarColor = payload[1]?.fill || 'var(--black)';
                                    const purchCost = payload[3]?.value || 0;
                                    const purchBarColor = payload[3]?.fill || 'var(--black)';

                                    return (
                                        <div className="bg-white border border-grey-darker p-2 rounded shadow max-w-xs">
                                            <p className="label">{`${label}`}</p>
                                            <div className="cost">
                                                <p style={{ color: turnoverBarColor }}>
                                                    {`Turnover: R ${turnover.toLocaleString(undefined, {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                    })}`}
                                                </p>
                                                <p style={{ color: costOfSalesBarColor }}>
                                                    {`AVG COS: R${costOfSales.toLocaleString(undefined, {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                    })}`}
                                                </p>
                                                <p style={{ color: cpdBarColor }}>
                                                    {`Actual Purchases: R ${cpdCost.toLocaleString(undefined, {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                    })}`}
                                                </p>
                                                <p style={{ color: purchBarColor }}>
                                                    {purchCost > 0 ? `Over Purchase by: R ${purchCost.toLocaleString(undefined, {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                    })}` : `Under Purchase by: R (${purchCost.toLocaleString(undefined, {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                    })})`}
                                                </p>
                                                <p style={{ color: dcBarColor }}>
                                                    {`Purchase from DC: R ${dcCost.toLocaleString(undefined, {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                    })}`}
                                                </p>

                                            </div>
                                        </div>
                                    );
                                }

                                return null;
                            }}
                        />
                        <Legend />
                        <CartesianGrid strokeDasharray="3 3" stroke="#9f9f9f" vertical={false} />
                        <Bar
                            dataKey="turnover"
                            fill="#0E5D8A"
                            label={barValue}
                            background={{ fill: 'transparent' }} />
                        <Bar
                            dataKey="costOfSales"
                            fill="#FFC400"
                            label={barValue}
                            background={{ fill: 'transparent' }} />
                        <Bar
                            fill="#8884d8"
                            dataKey="CPD Cost"
                            label={barValue}
                            background={{ fill: 'transparent' }} />
                        <Bar
                            dataKey="purchases"
                            fill="var(--red)"
                            label={barValue}
                            background={{ fill: 'transparent' }} />
                        <Bar
                            dataKey="DC Cost"
                            fill="var(--green)"
                            label={barValue}
                            background={{ fill: 'transparent' }} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <button className='absolute top-3 md:top-8 right-3 md:right-7 cursor-pointer flex items-end gap-2' onClick={() => toggleFilter(0)}>
                {state.searchResults ? `${state.month?.month} ${state.year}` : moment(primaryDates?.start_date).format('MMMM YYYY')}
                <img src={filterIcon} loading='lazy' alt='Filter' className='w-6' />
            </button>
            <div className={`absolute w-full h-full bg-black-light rounded-lg flex items-center justify-center ${activeContainer === 0 ? '' : 'hidden'} fade-in `} ref={(ref) => (containerRef.current[0] = ref)}>
                <div className='bg-grey w-5/12 rounded-lg flex items-end justify-center gap-4 p-2 relative flex-wrap'>
                    <button className='absolute top-1 right-1 cursor-pointer' onClick={closeFilter}>
                        <img src={closeIcon} loading='lazy' alt='Filter' className='w-7' />
                    </button>
                    <div className='w-full flex items-start justify-center gap-2 lg:ease-in-out duration-500 mt-8'>
                        <div className='flex flex-col justify-start gap-2 w-1/3'>
                            <div className='shadow p-2 flex items-center justify-between bg-white rounded-md' onClick={toggleMonth}>
                                <span className='w-full'>{state.month?.month || 'Select A Month'}</span>
                                <img src={bchev} loading='lazy' alt='Filter' className='w-5 rotate-180' />
                            </div>
                            {
                                !state.month &&
                                <div className={`h-40 overflow-y-scroll flex flex-col justify-start bg-white rounded-md fade-in `} >
                                    {months?.map((month) => <span className={`p-2 cursor-pointer hover:bg-grey lg:ease-in-out duration-500 ${state.month?.month === month?.month && 'bg-purple text-white hover:bg-purple'}`} key={month.key} onClick={() => setState({ ...state, month: month })}>{month.month}</span>)}
                                </div>
                            }
                        </div>
                        {
                            state.month &&
                            <div className='flex flex-col justify-start gap-2 w-1/3'>
                                <div className='shadow p-2 flex items-center justify-between bg-white rounded-md' onClick={toggleYear}>
                                    <span className='w-full'>{state.year || 'Select A Year'}</span>
                                    <img src={bchev} loading='lazy' alt='Filter' className='w-5 rotate-180' />
                                </div>
                                {
                                    (state.month && !state.year) &&
                                    <div className={`h-40 overflow-y-scroll flex flex-col justify-start bg-white rounded-md`}>
                                        {years?.map((year) => <span className={`p-2 cursor-pointer hover:bg-grey lg:ease-in-out duration-500 ${state.year === year && 'bg-purple text-white hover:bg-purple'}`} key={year} onClick={() => setState({ ...state, year: year })}>{year}</span>)}
                                    </div>
                                }
                            </div>
                        }
                        {(state.month && state.year) && <button className='flex flex-col gap-1 lg:ease-in-out duration-500 bg-purple text-white text-sm  shadow font-medium py-2 px-8 rounded tracking-wide' onClick={findData}>Filter</button>}
                    </div>
                </div>
            </div>
        </div>
    );
};
