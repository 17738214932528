import { Layout } from "../layout/layout";

import tempIcon from '../assets/icons/temp.svg'
import hoursIcon from '../assets/icons/hours.svg'

export const IOT = () => {
    return (
        <Layout>
            <div className="p-2">
                <div className="flex items-start justify-start gap-4">
                    <a href='/iot-hours' className="flex items-center justify-center w-full h-20 gap-2 p-2 bg-white rounded shadow lg:w-2/12 lg:hover:bg-purple lg:ease-in-out lg:duration-300 group">
                        <img src={hoursIcon} alt='Temperature' loading="lazy" className="w-10" />
                        <span className="font-medium text-gray-500 text-md group-hover:text-white">Work Hours</span>
                    </a>
                    <a href='/iot-temps' className="flex items-center justify-center w-full h-20 gap-2 p-2 bg-white rounded shadow lg:w-2/12 lg:hover:bg-purple lg:ease-in-out lg:duration-300 group">
                        <img src={tempIcon} alt='Temperature' loading="lazy" className="w-20" />
                        <span className="font-medium text-gray-500 text-md group-hover:text-white">Frier Temperatures</span>
                    </a>
                </div>
            </div>
        </Layout>
    )
}